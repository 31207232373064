import { Avatar, Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import iconHeader from "../../assets/images/icon-bell-header.png";
import { useSelector } from "react-redux";
const Header = () => {
  const user = useSelector((state) => state.auth.login?.currentUser);
  return (
    <Flex
      width={"100%"}
      h={{ base: "80px" }}
      justifyContent={"flex-end"}
      borderBottom={"0.5px solid var(--color-boder)"}
    >
      <Box p={"15px 32px"}>
        <Flex
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={{ base: "32px" }}
        >
          <Button
            w={{ base: "50px" }}
            h={{ base: "50px" }}
            borderRadius={{ base: "50%" }}
          >
            <Image src={iconHeader} />
          </Button>
          <Flex alignItems={"center"} gap={{ base: "16px" }}>
            <Avatar
              w={{ base: "50px" }}
              h={{ base: "50px" }}
              borderRadius={{ base: "50%" }}
              src={user?.user?.avatar && `${process.env.REACT_APP_BACKEND_API}/${user?.user.avatar}`}
            />
            <Flex gap={{ base: "6px" }} flexDirection={{ base: "column" }}>
              <Text fontSize={{ base: "16px" }} fontWeight={600}>
                {user?.user.fullName}
              </Text>
              <Text fontSize={{ base: "14px" }} fontWeight={500}>
                Nhân viên
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
