import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Image,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import calendarIcon from "../../assets/images/onleave/calendar.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import { SelectControl } from "../../components/form/formControls";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; 

const TableOnLeave = ({tableData}) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      saleArea: "-1",
      saleBlock: "-1",
    },
  });

  // table
  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState(null);
  const [saleBlock, setSaleBlock] = useState(null);
  const [inputColors, setInputColors] = useState({
    date: "var(--color-text-unclick)",
  });
  const [focusStates, setFocusStates] = useState({});

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });
 
  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;

    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };
  //
  // const saleAreas = Object.entries(constantData?.saleAreas || {}).map(
  //   ([key, value]) => {
  //     return {
  //       key,
  //       value,
  //     };
  //   }
  // );
  // const saleBlocks = Object.entries(constantData?.saleBlocks || {}).map(
  //   ([key, value]) => {
  //     return {
  //       key,
  //       value,
  //     };
  //   }
  // );
  const saleAreas = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ]

  const saleBlocks = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ]

  const handleFocus = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeBranch = (option) => { 
    setValue("saleArea", Number(option));
  };

  const onchangeBlock = (option) => {
    setValue("saleBlock", Number(option));
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          w={{ base: "90%" }}
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup
            maxW="506px"
            borderRadius={{ base: "8px" }}
            w={{ base: "40%" }}
          >
            <Input
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
              onChange={onChangeSearch}
            />
            <InputRightElement
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }}>
            <InputGroup>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Từ ngày - Đến ngày"
                className="datePickerStyle"
              />
              {(!startDate && !endDate) && <InputRightElement pointerEvents="none" h={"44px"}>
                <Image src={calendarIcon} />
              </InputRightElement>}
            </InputGroup>
            <SelectControl
              dataOption={saleAreas}
              width="200px"
              placeholder="Chọn vùng"
              onChange={onchangeBranch}
              isSearchable={true}
              selectedMain={watch("saleArea")}
              onFocus={() => handleFocus("saleArea")}
              onBlur={() => handleBlur("saleArea")}
              state={focusStates["saleArea"]}
            />
            <SelectControl
              dataOption={saleBlocks}
              width="200px"
              placeholder="Chọn khối"
              isSearchable={true}
              onChange={onchangeBlock}
              selectedMain={watch("saleBlock")}
              onFocus={() => handleFocus("saleBlock")}
              onBlur={() => handleBlur("saleBlock")}
              state={focusStates["saleBlock"]}
            />
          </Flex>
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {tableData.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "") {
                      width = "2%";
                      textAlign = "center";
                    } else if (e.key === "num") {
                      width = "5%";
                      textAlign = "center";
                    } else if (e.key === "status") {
                      width = "10%";
                      textAlign = "center";
                    } else if (e.key === "name") {
                      width = "25%";
                      textAlign = "start";
                    } else if (e.key === "timesupport") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "timelate") {
                      width = "10%";
                      textAlign = "start";
                    } else {
                      width = "20%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {tableData.data?.length > 0 ? (
                  tableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"}>
                        {keyValues.map((keyvalue, index) => {
                          let width = "auto";
                          let widthText = "100%";
                          let textAlign = "auto";
                          let whiteSpace = "inherit";
                          if (keyvalue === "") {
                            width = "2%";
                            textAlign = "center";
                          } else if (keyvalue === "contact") {
                            width = "5%";
                            textAlign = "start";
                          } else if (keyvalue === "status") {
                            width = "10%";
                            textAlign = "center";
                          } else if (keyvalue === "name") {
                            width = "25%";
                            textAlign = "start";
                            whiteSpace = "normal";
                            widthText = "118px";
                          } else if (keyvalue === "identificationInfo") {
                            width = "10%";
                            textAlign = "start";
                          } else if (keyvalue === "identificationNumber") {
                            width = "10%";
                            textAlign = "start";
                          }
                          else if (keyvalue === "permanentAddress") {
                            width = "40%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "171px";
                          }
                          else if (keyvalue === "currentAddress") {
                            width = "40%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "146px";
                          }
                          else if (keyvalue === "project") {
                            width = "20%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "143px";
                          }
                          else {
                            width = "20%";
                            textAlign = "end";
                            whiteSpace = "inherit";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index}  >
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                color={"#293755"}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}  
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={tableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>Showing 1 to 10 of 12 entries</Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={8}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={8}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />}
                  nextLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />}
                  renderOnZeroPageCount={null}
                  initialPage={8 - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};
export default TableOnLeave;