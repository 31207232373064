import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createAxios } from "../../createInstance";
import { loginSuccess } from "../../redux/slices/authSlice";
import { getAllUsers } from "../../redux/slices/apiRequestsSlice";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import SalesPerson from "./salesPerson";
import { useTab } from "../../contexts/useTab";
import BusinessSupportStaff from "./businessSupportStaff";
import iconButton from "../../assets/images/employee/icon-button.svg";
import FormEmployee from "../../components/form/formEmployee";
import { useEmployee } from "../../hooks/useEmployee";
import { commonCall } from "../../api/common";
import toast from "react-hot-toast";
import { employeeCall } from "../../api/employee";
import { delay } from "../../utils/utils";

export default function Employee() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const { employeeTab, setEmployeeTab } = useTab();

  const { refetch, isRefetching, constantData } = useEmployee(employeeTab);

  const tabEmployee = [
    {
      title: "Nhân viên khối kinh doanh",
      content: (
        <SalesPerson department={1} isRefetchingWithCreate={isRefetching} />
      ),
    },
    {
      title: "NV khối hỗ trợ kinh doanh",
      content: (
        <BusinessSupportStaff
          department={0}
          isRefetchingWithCreate={isRefetching}
        />
      ),
    },
  ];

  const onSubmit = async (data) => {
    const toastCreate = toast.loading("Đang thêm nhân viên ...");
    try {
      let filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([_, value]) => value !== "" && value !== undefined && value !== null
        )
      );
      if (filteredData?.file) {
        const { filePath } = await commonCall.uploadAvatar(filteredData?.file);
        if (filePath) filteredData.avatar = filePath;
      }
      delete filteredData.file;
      // test
      filteredData.code = `MSH${Math.floor(1000 + Math.random() * 9000)}`;
      //
      await employeeCall.create({ ...filteredData });
      await refetch();
      onToggle();
      toast.success("Thêm thành công");
    } catch (error) {
      console.log({ error });
    }
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý nhân viên
          </Text>
          <Flex
            justifyContent={{ base: "space-around", xl: "flex-start" }}
            gap={{ base: "24px" }}
          >
            {tabEmployee.map((e, index) => {
              return (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  key={index}
                  onClick={() => setEmployeeTab(index)}
                  cursor={"pointer"}
                  padding={"12px 5px 0px 5px"}
                  zIndex={"10"}
                >
                  <Text
                    position={"relative"}
                    paddingBottom={"8px"}
                    _before={{
                      content: '""',
                      position: "absolute",
                      bottom: "-1px",
                      left: 0,
                      width: "100%",
                      height: {
                        base: employeeTab === index ? "2px" : "0",
                      },
                      backgroundColor: "var(--color-secondary)",
                    }}
                    fontSize={{
                      base: "16px",
                    }}
                    fontWeight={600}
                    lineHeight={"normal"}
                    fontFamily="var(--font-heading-main)"
                    color={
                      employeeTab === index
                        ? "var(--color-secondary)"
                        : "#9D9D9D"
                    }
                    textAlign={"center"}
                    whiteSpace="nowrap"
                  >
                    {e?.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Button
          display={"flex"}
          alignItems={"center"}
          p={"10px 16px 10px 12px"}
          gap={"8px"}
          backgroundImage="var(--color-button)"
          onClick={onOpen}
          _hover={{
            filter: "brightness(90%)",
        }}
        >
          <Image src={iconButton} />
          <Text
            fontSize={{ base: "15px" }}
            fontWeight={600}
            color="var(--color-main)"
          >
            Thêm nhân viên
          </Text>
        </Button>
      </Flex>
      <Box mt={{ base: "23px" }}>{tabEmployee[employeeTab].content}</Box>
      <FormEmployee
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        department={!employeeTab}
        constantData={constantData}
      />
    </Box>
  );
}