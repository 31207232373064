import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  FormControl,
  Input,
  Button,
  InputGroup,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  Avatar,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { RadioControl, SelectControl } from "./formControls";
import NonAvatar from "../Icons/avartar";
import { formatDate } from "../../utils/utils";
import useDebounce from "../../hooks/useDebounce";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
  fullName: Yup.string().required("Vui lòng nhập họ tên"),
  password: Yup.string()
  .required("Mật khẩu là bắt buộc")
  .matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[a-zA-Z0-9!@#$%&*]+$/,
    "Mật khẩu phải có ít nhất 1 chữ hoa, 1 chữ thường, 1 số, 1 ký tự đặc biệt, và không chứa dấu."
  ),
  phone: Yup.string()
    .required("Vui lòng nhập số điện thoại")
    .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
  email: Yup.string()
    .required("Vui lòng nhập email")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
  identificationNumber: Yup.string()
    .required("Vui lòng nhập CCCD")
    .min(9, "Số CCCD phải có ít nhất 9 chữ số.")
    .max(12, "Số CCCD không được quá 12 chữ số.")
    .matches(/^\d{9,12}$/, "Số CCCD không hợp lệ. Vui lòng nhập từ 9 đến 12 chữ số."),
  dateOfIdentification: Yup.date()
  .nullable()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value; 
  })
  .required("Vui lòng chọn ngày cấp")
  .typeError("Thời gian cấp không hợp lệ."), 
  placeOfIdentification: Yup.string().required("Vui lòng nhập nơi cấp"),
  permanentAddress: Yup.string().required("Vui lòng nhập địa chỉ thường trú"),
  currentAddress: Yup.string().required("Vui lòng nhập chỗ ở hiện tại"),
  bankAccount: Yup.string().required("Vui lòng nhập số tài khoản ngân hàng"),
  bankName: Yup.string().required("Vui lòng nhập tên ngân hàng"),
  startTime: Yup.date()
  .nullable()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value; 
  })
  .required("Vui lòng chọn thời gian bắt đầu")
  .typeError("Thời gian bắt đầu không hợp lệ."), 
});

const schemaEdit = Yup.object().shape({
  fullName: Yup.string().required("Vui lòng nhập họ tên"),
  phone: Yup.string()
    .required("Vui lòng nhập số điện thoại")
    .matches(/^[0-9]{10,11}$/, "Số điện thoại không hợp lệ."),
  email: Yup.string()
    .required("Vui lòng nhập email")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email không hợp lệ"),
  identificationNumber: Yup.string()
    .required("Vui lòng nhập CCCD")
    .min(9, "Số CCCD phải có ít nhất 9 chữ số.")
    .max(12, "Số CCCD không được quá 12 chữ số.")
    .matches(/^\d{9,12}$/, "Số CCCD không hợp lệ. Vui lòng nhập từ 9 đến 12 chữ số."),
  dateOfIdentification: Yup.date()
  .nullable()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value; 
  })
  .required("Vui lòng chọn ngày cấp")
  .typeError("Thời gian cấp không hợp lệ."), 
  placeOfIdentification: Yup.string().required("Vui lòng nhập nơi cấp"),
  permanentAddress: Yup.string().required("Vui lòng nhập địa chỉ thường trú"),
  currentAddress: Yup.string().required("Vui lòng nhập chỗ ở hiện tại"),
  bankAccount: Yup.string().required("Vui lòng nhập số tài khoản ngân hàng"),
  bankName: Yup.string().required("Vui lòng nhập tên ngân hàng"),
  startTime: Yup.date()
  .nullable()
  .transform((value, originalValue) => {
    return originalValue === "" ? null : value; 
  })
  .required("Vui lòng chọn thời gian bắt đầu")
  .typeError("Thời gian bắt đầu không hợp lệ."), 
});


export const FormEmployee = memo(
  ({ isOpen, onClose, department, defaultValues, onSubmit, constantData }) => {
    const {
      register,
      getValues,
      handleSubmit,
      setValue,
      watch,
      reset,
      formState: { errors },
    } = useForm({
      resolver: defaultValues ? yupResolver(schemaEdit) : yupResolver(schema),
      defaultValues: defaultValues || {},
    });
    const debouncedIsOpen = useDebounce(isOpen, 300);

    useEffect(() => {
      if (isOpen) {
        reset(defaultValues);
        setAvatar(null);
      }
    }, [isOpen, defaultValues, reset]);

    const formatEntriesForSelect = (entries) =>
      Object.entries(entries || {}).map(([key, value]) => ({
        value: key,
        label: value,
      }));

    const jobTitles = useMemo(
      () => formatEntriesForSelect(constantData?.jobTitles),
      [constantData?.jobTitles]
    );
    const branches = useMemo(
      () => formatEntriesForSelect(constantData?.branches),
      [constantData?.branches]
    );
    const departments = useMemo(
      () => formatEntriesForSelect(constantData?.departments),
      [constantData?.departments]
    );
    const businessCategorys = useMemo(
      () => formatEntriesForSelect(constantData?.businessCategorys),
      [constantData?.businessCategorys]
    );
    const guaranteeTypes = useMemo(
      () => formatEntriesForSelect(constantData?.guaranteeTypes),
      [constantData?.guaranteeTypes]
    );
    const saleAreas = useMemo(
      () => formatEntriesForSelect(constantData?.saleAreas),
      [constantData?.saleAreas]
    );
    const saleBlocks = useMemo(
      () => formatEntriesForSelect(constantData?.saleBlocks),
      [constantData?.saleBlocks]
    );

    const onchangeJobTitle = (option) => {
      setValue("jobTitle", Number(option));
    };

    const onchangeBranch = (option) => {
      setValue("branch", Number(option));
    };

    const onchangeBusinessCategorys = (option) => {
      setValue("businessCategoryId", Number(option));
    };

    const onchangeGuaranteeTypes = (option) => {
      setValue("guaranteeTypeId", Number(option));
    };

    const onchangeDepartments = (option) => {
      setValue("department", Number(option));
    };

    const onchangeSaleAreas = (option) => {
      setValue("saleArea", Number(option));
    };

    const onchangeSaleBlocks = (option) => {
      setValue("saleBlock", Number(option));
    };

    const status = [
      { value: 0, label: "Đang làm việc" },
      { value: 1, label: "Dừng làm việc" },
    ];

    const [focusStates, setFocusStates] = useState({});
    // eslint-disable-next-line
    const [disableInput, setDisableInput] = useState(true);
    const [inputColors, setInputColors] = useState({
      startTime: "var(--color-text-unclick)",
      endTime: "var(--color-text-unclick)",
      guaranteeDate: "var(--color-text-unclick)",
      dateOfIdentification: "var(--color-text-unclick)",
      depositDate: "var(--color-text-unclick)",
    });

    const handleFocus = (field) => {
      setFocusStates((prev) => ({ ...prev, [field]: true }));
    };

    const handleBlur = (field) => {
      setFocusStates((prev) => ({ ...prev, [field]: false }));
    };

    const fileAvatarInputRef = useRef(null);
    const [avatar, setAvatar] = useState(null);

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const imageDataUrl = e.target.result;
          setAvatar(imageDataUrl);
          setValue("file", file);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleClick = () => {
      if (fileAvatarInputRef.current) {
        fileAvatarInputRef.current.click();
      }
    };
    const handleDateChange = (e, inputName) => {
      const selectedDate = e.target.value;
      setValue(inputName, selectedDate);
      setInputColors((prevColors) => ({
        ...prevColors,
        [inputName]: selectedDate
          ? "var(--color-info-employee)"
          : "var(--color-text-unclick)",
      }));
    };

    const handleActionStateChange = (option) => {
      setValue("status", option);
    };

    const submitHandler = (data) => {
      onSubmit(data);
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="fade">
        <ModalOverlay />
        <ModalContent
          backgroundColor="var(--color-main)"
          borderRadius={"16px"}
          padding={"24px"}
          h={"86.5vh"}
          w={"100%"}
          maxW={"625px"}
          overflowY={"scroll"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
            "&::-moz-scrollbar": {
              width: "4px",
            },
            "&::-moz-scrollbar-thumb": {
              width: "4px",
              borderRadius: "50px",
              background: "var(--color-background)",
            },
          }}
        >
          <form onSubmit={handleSubmit(submitHandler)}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={"10px"}
            >
              <Text
                color="var(--color-header-employee)"
                fontSize={"18px"}
                fontWeight={"600"}
              >
                {Boolean(department)
                  ? defaultValues
                    ? "Sửa thông tin nhân viên kinh doanh"
                    : "Nhân viên kinh doanh mới"
                  : defaultValues
                  ? "Sửa thông tin nhân viên hỗ trợ kinh doanh"
                  : "Nhân viên hỗ trợ kinh doanh mới"}
              </Text>
              <Box cursor={"pointer"} onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 9L9 15"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 9L15 15"
                    stroke="#99A4B8"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Box>
            </Flex>
            <Box
              w={"100%"}
              h={"1px"}
              backgroundColor={"var(--bg-line-employee)"}
              marginTop={"26px"}
              marginBottom={"24px"}
            ></Box>
            <Box w={"100%"} maxW={"545px"} m={"0 auto"}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"flex-start"}
                gap={"27px"}
              >
                <Box flex={"1"} position={"relative"}>
                  <Text
                    fontSize={"16px"}
                    fontWeight={"600"}
                    color={"var(--color-header-employee)"}
                    marginBottom={"16px"}
                  >
                    Ảnh đại diện
                  </Text>
                  <Flex
                    w={"120px"}
                    h={"120px"}
                    borderRadius={"50%"}
                    bgColor={"var(--fill-avatar)"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={handleClick}
                    cursor="pointer"
                    position={"relative"}
                  >
                    {avatar || watch("avatar") ? (
                      <Avatar
                        src={
                          avatar ||
                          `${process.env.REACT_APP_BACKEND_API}/${watch(
                            "avatar"
                          )}`
                        }
                        size="2xl"
                        name=""
                      />
                    ) : (
                      <NonAvatar />
                    )}
                  </Flex>
                  <Input
                    ref={fileAvatarInputRef}
                    position={"absolute"}
                    type="file"
                    w={"0"}
                    h={"0"}
                    border={"0"}
                    outline={"0"}
                    padding={"0"}
                    m={"0"}
                    overflow={"hidden"}
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg"
                  />
                </Box>
                <Box flex={"1"}>
                  <Text
                    color={"var(--color-header-employee)"}
                    fontSize={"16px"}
                    fontWeight={"600"}
                    mb={"16px"}
                  >
                    Thông tin tài khoản
                  </Text>
                  <Box mb={"13px"}>
                    <Text
                      fontSize={"12px"}
                      fontWeight={"400"}
                      color={"var(--color-label-employee)"}
                      mb={"8px"}
                    >
                      Mã nhân viên
                    </Text>
                    <Text
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color={"var(--color-info-employee)"}
                    >
                      {getValues("code")}
                    </Text>
                  </Box>
                  <FormControl mb={{ base: "16px" }}>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["password"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Mật khẩu
                    </FormLabel>
                    <Input
                    maxW={"259px"}
                    h={"44px"}
                    fontSize={{ base: "14px" }}
                    fontWeight={"500"}
                    padding={"13px 12px 14px 12px"}
                    borderRadius={"8px"}
                    border={"0.5px solid var(--bg-line-employee)"}
                    placeholder="Đặt mật khẩu"
                    sx={{
                      "&::placeholder": {
                        color: "var(--color-placeHolder-employee)",
                      },
                    }}
                    _focus={{
                      boxShadow: "none",
                      borderColor: "var(--color-option-employee-hover)",
                    }}
                    onFocus={() => handleFocus("password")}
                    {...register("password")} // Sử dụng trực tiếp register
                    onBlur={() => handleBlur("password")}
                    type="password"
                  />
                   {errors.password && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.password.message}
                      </Text>
                    )}
                  </FormControl>
                </Box>
              </Flex>
              {/* Khooi 1 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin cá nhân
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["fullName"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Họ và tên
                    </FormLabel>
                    <Input
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập họ và tên"
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("fullName")}
                      {...register("fullName")}
                      onBlur={() => handleBlur("fullName")}
                    />
                    {errors.fullName && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.fullName.message}
                      </Text>
                    )}
                  </FormControl>
                  <SelectControl
                    label="Chi nhánh"
                    dataOption={branches}
                    selectedMain={watch("branch")}
                    placeholder="Chọn chi nhánh"
                    onChange={onchangeBranch}
                    onFocus={() => handleFocus("branch")}
                    onBlur={() => handleBlur("branch")}
                    state={focusStates["branch"]}
                  />
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <SelectControl
                    label="Khối bộ phận"
                    dataOption={departments}
                    selectedMain={watch("department")}
                    placeholder="Chọn khối bộ phận"
                    onChange={onchangeDepartments}
                    onFocus={() => handleFocus("department")}
                    onBlur={() => handleBlur("department")}
                    state={focusStates["department"]}
                  />
                  <SelectControl
                    label="Chức danh"
                    dataOption={jobTitles}
                    selectedMain={watch("jobTitle")}
                    placeholder="Chọn chức danh"
                    onChange={onchangeJobTitle}
                    onFocus={() => handleFocus("jobTitle")}
                    onBlur={() => handleBlur("jobTitle")}
                    state={focusStates["jobTitle"]}
                  />
                </Flex>
                {/* 3 */}
                {Boolean(department) && (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <SelectControl
                      label="Vùng"
                      dataOption={saleAreas}
                      selectedMain={watch("saleArea")}
                      placeholder="Chọn vùng"
                      isSearchable={true}
                      onChange={onchangeSaleAreas}
                      onFocus={() => handleFocus("saleArea")}
                      onBlur={() => handleBlur("saleArea")}
                      state={focusStates["saleArea"]}
                    />
                    <SelectControl
                      label="Khối"
                      selectedMain={watch("saleBlock")}
                      dataOption={saleBlocks}
                      placeholder="Chọn khối"
                      onChange={onchangeSaleBlocks}
                      onFocus={() => handleFocus("saleBlock")}
                      onBlur={() => handleBlur("saleBlock")}
                      state={focusStates["saleBlock"]}
                    />
                  </Flex>
                )}
              </Box>
              {/* Khooi 2 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Hoạt động
                </Text>
                {/* 1 */}
                <FormLabel
                  fontSize={{ base: "12px" }}
                  fontWeight={400}
                  color={"var(--color-label-employee)"}
                  mb={"12px"}
                >
                  Trạng thái hoạt động
                </FormLabel>

                <RadioControl
                  options={status}
                  selectedValue={watch("status")}
                  onChange={handleActionStateChange}
                />
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["startTime"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                      // color={"var(--color-label-employee)"}
                    >
                      Ngày vào làm
                    </FormLabel>
                    <InputGroup>
                       <Input
                        maxW={"259px"}
                        h={"44px"}
                        padding={"13px 12px 14px 12px"}
                        type="date"
                        placeholder="Ngày cấp"
                        color={
                          getValues("startTime")
                            ? "var(--color-info-employee)"
                            : inputColors.startTime
                        }
                        onChange={(e) =>
                          handleDateChange(e, "startTime")
                        }
                        value={watch("startTime") || ""}
                        sx={{
                          "::placeholder": {
                            color: "var(--color-placeHolder-employee)",
                          },
                        }}
                        onFocus={() => handleFocus("startTime")}
                        {...register("startTime")}
                        onBlur={() => handleBlur("startTime")}
                      />
                    </InputGroup>
                    {errors.startTime && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.startTime.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        disableInput
                          ? "var(--bg-line-employee)"
                          : focusStates["endTime"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày nghỉ việc
                    </FormLabel>
                    <InputGroup>
                      <Input
                        maxW={"259px"}
                        h={"44px"}
                        padding={"13px 12px 14px 12px"}
                        type="date"
                        placeholder="dd/mm/yyyy"
                        disabled={disableInput ? true : false}
                        color={
                          disableInput
                            ? "var(--bg-line-employee)"
                            : inputColors.endTime
                        }
                        onChange={(e) => handleDateChange(e, "endTime")}
                        sx={{
                          "&::placeholder": {
                            color: "var(--color-placeHolder-employee)",
                          },
                        }}
                      />
                    </InputGroup>
                  </FormControl>
                </Flex>
              </Box>
              {/* Khooi 3 */}
              {Boolean(department) && (
                <Box mt={"20px"}>
                  <Text
                    fontSize={"16px"}
                    lineHeight={"19px"}
                    fontWeight={"600"}
                    color={"var(--color-header-employee)"}
                    mb={"16px"}
                  >
                    Phân loại nhân sự
                  </Text>
                  {/* 1 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Loại nhân sự"
                      dataOption={businessCategorys}
                      selectedMain={watch("businessCategoryId")}
                      placeholder="Chọn loại nhân sự"
                      onChange={onchangeBusinessCategorys}
                      onFocus={() => handleFocus("businessCategoryId")}
                      onBlur={() => handleBlur("businessCategoryId")}
                      state={focusStates["businessCategoryId"]}
                    />
                    <FormControl>
                      <FormLabel
                        mb={"6px"}
                        fontSize={{ base: "12px" }}
                        fontWeight={400}
                        color={
                          focusStates["referralCode"]
                            ? "var(--color-option-employee-hover)"
                            : "var(--color-label-employee)"
                        }
                      >
                        Mã giới thiệu
                      </FormLabel>
                      <Input
                        maxW={"259px"}
                        h={"44px"}
                        fontSize={{ base: "14px" }}
                        fontWeight={"500"}
                        padding={"13px 12px 14px 12px"}
                        borderRadius={"8px"}
                        border={"0.5px solid var(--bg-line-employee)"}
                        placeholder="Nhập mã giới thiệu"
                        value={getValues("referralCodeId") || ""}
                        onChange={(e) =>
                          setValue("referralCodeId", e.target.value)
                        }
                        sx={{
                          "&::placeholder": {
                            color: "var(--color-placeHolder-employee)",
                          },
                        }}
                        _focus={{
                          boxShadow: "none",
                          borderColor: "var(--color-option-employee-hover)",
                        }}
                        onFocus={() => handleFocus("referralCodeId")}
                        onBlur={() => handleBlur("referralCodeId")}
                      />
                    </FormControl>
                  </Flex>
                  {/* 2 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                    mb={"12px"}
                  >
                    <SelectControl
                      label="Loại bảo lãnh"
                      dataOption={guaranteeTypes}
                      placeholder="Chọn loại bảo lãnh"
                      selectedMain={watch("guaranteeTypeId")}
                      onChange={onchangeGuaranteeTypes}
                      onFocus={() => handleFocus("guaranteeTypeId")}
                      onBlur={() => handleBlur("guaranteeTypeId")}
                      state={focusStates["guaranteeTypeId"]}
                    />
                    <FormControl>
                      <FormLabel
                        mb={"6px"}
                        fontSize={{ base: "12px" }}
                        fontWeight={400}
                        color={
                          focusStates["guaranteeDate"]
                            ? "var(--color-option-employee-hover)"
                            : "var(--color-label-employee)"
                        }
                      >
                        Ngày bảo lãnh
                      </FormLabel>
                      <InputGroup>
                        <Input
                          maxW={"259px"}
                          h={"44px"}
                          padding={"13px 12px 14px 12px"}
                          type="date"
                          placeholder="Ngày bảo lãnh"
                          color={
                            getValues("guaranteeDate")
                              ? "var(--color-info-employee)"
                              : inputColors.guaranteeDate
                          }
                          onChange={(e) => handleDateChange(e, "guaranteeDate")}
                          value={watch("guaranteeDate") || ""}
                          sx={{
                            "::placeholder": {
                              color: "var(--color-placeHolder-employee)",
                            },
                          }}
                          onFocus={() => handleFocus("guaranteeDate")}
                          {...register("guaranteeDate")}
                          onBlur={() => handleBlur("guaranteeDate")}
                        />
                      </InputGroup>
                    </FormControl>
                  </Flex>
                  {/* 3 */}
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                    gap={"27px"}
                  >
                    <FormControl>
                      <FormLabel
                        mb={"6px"}
                        fontSize={{ base: "12px" }}
                        fontWeight={400}
                        color={
                          focusStates["depositDate"]
                            ? "var(--color-option-employee-hover)"
                            : "var(--color-label-employee)"
                        }
                      >
                        Ngày ký quỹ
                      </FormLabel>
                      <InputGroup position={"relative"} maxW={"259px"}>
                        <Input
                          maxW={"259px"}
                          h={"44px"}
                          padding={"13px 12px 14px 12px"}
                          type="date"
                          placeholder="Ngày ký quỹ"
                          color={
                            getValues("depositDate")
                              ? "var(--color-info-employee)"
                              : inputColors.depositDate
                          }
                          onChange={(e) => handleDateChange(e, "depositDate")}
                          value={watch("depositDate") || ""}
                          sx={{
                            "::placeholder": {
                              color: "var(--color-placeHolder-employee)",
                            },
                          }}
                          onFocus={() => handleFocus("depositDate")}
                          {...register("depositDate")}
                          onBlur={() => handleBlur("depositDate")}
                        />
                      </InputGroup>
                    </FormControl>
                  </Flex>
                </Box>
              )}
              {/* Khooi 4 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin liên hệ
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["phone"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Số điện thoại
                    </FormLabel>
                    <Input
                      type="number"
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập số điện thoại"
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("phone")}
                      {...register("phone", {
                        required: "Vui lòng nhập số điện thoại",
                        pattern: {
                          value: /^[0-9]{10,11}$/,
                          message: "Số điện thoại không hợp lệ.",
                        },
                      })}
                      onBlur={() => handleBlur("phone")}
                    />
                    {errors.phone && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.phone.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["email"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Email
                    </FormLabel>
                    <Input
                      type="email"
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập email"
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("email")}
                      {...register("email", {
                        required: "Vui lòng nhập email",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Email không hợp lệ",
                        },
                      })}
                      onBlur={() => handleBlur("email")}
                    />
                    {errors.email && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.email.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["currentAddress"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Chỗ ở hiện tại
                    </FormLabel>
                    <Input
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập chỗ ở hiện tại"
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("currentAddress")}
                      {...register("currentAddress", {
                        required: "Vui lòng nhập chỗ ở hiện tại",
                      })}
                      onBlur={() => handleBlur("currentAddress")}
                    />
                    {errors.currentAddress && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.currentAddress.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              </Box>
              {/* Khooi 5 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin giấy tờ
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                  mb={"12px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["identificationNumber"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Số CCCD/CMND
                    </FormLabel>
                    <Input
                      type="number"
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập số CCCD/CMND"
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("identificationNumber")}
                      {...register("identificationNumber", {
                        required: "Vui lòng nhập CCCD",
                        validate: {
                          validLength: value => value.length === 12 || "Số CCCD phải có 12 chữ số.",
                          validFormat: value => /^\d{12}$/.test(value) || "Số CCCD không hợp lệ. Vui lòng nhập 12 chữ số."
                        }
                      })}
                      onBlur={() => handleBlur("identificationNumber")}
                    />
                    {errors.identificationNumber && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.identificationNumber.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["dateOfIdentification"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Ngày cấp
                    </FormLabel>
                    <InputGroup>
                      <Input
                        maxW={"259px"}
                        h={"44px"}
                        padding={"13px 12px 14px 12px"}
                        type="date"
                        placeholder="Ngày cấp"
                        color={
                          getValues("dateOfIdentification")
                            ? "var(--color-info-employee)"
                            : inputColors.dateOfIdentification
                        }
                        onChange={(e) =>
                          handleDateChange(e, "dateOfIdentification")
                        }
                        value={watch("dateOfIdentification") || ""}
                        sx={{
                          "::placeholder": {
                            color: "var(--color-placeHolder-employee)",
                          },
                        }}
                        onFocus={() => handleFocus("dateOfIdentification")}
                        {...register("dateOfIdentification")}
                        onBlur={() => handleBlur("dateOfIdentification")}
                      />
                    </InputGroup>
                    {errors.dateOfIdentification && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.dateOfIdentification.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
                {/* 2 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["placeOfIdentification"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Nơi cấp
                    </FormLabel>
                    <Input
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập nơi cấp..."
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("placeOfIdentification")}
                      {...register("placeOfIdentification", {
                        required: "Vui lòng nhập nơi cấp",
                      })}
                      onBlur={() => handleBlur("placeOfIdentification")}
                    />
                    {errors.placeOfIdentification && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.placeOfIdentification.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["permanentAddress"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Địa chỉ thường trú
                    </FormLabel>
                    <Input
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập địa chỉ thường trú..."
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      onFocus={() => handleFocus("permanentAddress")}
                      {...register("permanentAddress", {
                        required: "Vui lòng nhập địa chỉ thường trú",
                      })}
                      onBlur={() => handleBlur("permanentAddress")}
                    />
                    {errors.permanentAddress && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.permanentAddress.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              </Box>
              {/* Khooi 6 */}
              <Box mt={"20px"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"19px"}
                  fontWeight={"600"}
                  color={"var(--color-header-employee)"}
                  mb={"16px"}
                >
                  Thông tin thanh toán
                </Text>
                {/* 1 */}
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"flex-start"}
                  gap={"27px"}
                >
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["bankAccount"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Số tài khoản
                    </FormLabel>
                    <Input
                      type="number"
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập số tài khoản NH..."
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      value={watch("bankAccount") || ""}
                      {...register("bankAccount")}
                      onChange={(e) => setValue("bankAccount", e.target.value)}
                      onFocus={() => handleFocus("bankAccount")}
                      onBlur={() => handleBlur("bankAccount")}
                    />
                    {errors.bankAccount && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.bankAccount.message}
                      </Text>
                    )}
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      mb={"6px"}
                      fontSize={{ base: "12px" }}
                      fontWeight={400}
                      color={
                        focusStates["bankName"]
                          ? "var(--color-option-employee-hover)"
                          : "var(--color-label-employee)"
                      }
                    >
                      Tên ngân hàng
                    </FormLabel>
                    <Input
                      maxW={"259px"}
                      h={"44px"}
                      fontSize={{ base: "14px" }}
                      fontWeight={"500"}
                      padding={"13px 12px 14px 12px"}
                      borderRadius={"8px"}
                      border={"0.5px solid var(--bg-line-employee)"}
                      placeholder="Nhập tên ngân hàng"
                      value={watch("bankName") || ""}
                      onChange={(e) => setValue("bankName", e.target.value)}
                      sx={{
                        "&::placeholder": {
                          color: "var(--color-placeHolder-employee)",
                        },
                      }}
                      _focus={{
                        boxShadow: "none",
                        borderColor: "var(--color-option-employee-hover)",
                      }}
                      {...register("bankName")}
                      onFocus={() => handleFocus("bankName")}
                      onBlur={() => handleBlur("bankName")}
                    />
                    {errors.bankName && (
                      <Text fontSize={{ base: "12px" }} color={"red"}>
                        {errors.bankName.message}
                      </Text>
                    )}
                  </FormControl>
                </Flex>
              </Box>
            </Box>
            <Flex
              justifyContent={"flex-end"}
              mt={"24px"}
              w={"100%"}
              maxW={"545px"}
            >
              <Button
                type="submit"
                borderRadius={"8px"}
                background={"var(--linear-gradient-employee)"}
                color={"var(--color-main)"}
                w={"164px"}
                h={"44px"}
                _hover={{
                  filter: "brightness(90%)",
                }}
              >
                {defaultValues ? "Lưu thông tin" : "Thêm nhân viên"}
              </Button>
            </Flex>
          </form>
        </ModalContent>
      </Modal>
    );
  }
);

export default FormEmployee;