import SignIn from "../pages/signIn/index";
import Employee from "../pages/employee/index";
import Form from "../pages/Form/index";
import NotFoundPage from "../pages/notFoundPage";
import JobTitle from "../pages/jobTitle/index";
import Apartments from "../pages/apartments/index";
import OnLeave from "../pages/onLeave/index";
import FormInfo from "../components/form/formInfo";
import TimeKeeping from "../pages/timeKeeping";
import Customers from "../pages/customers";
import ProjectManagement from "../pages/projectManagement";

export const routes = [
  { path: "/login", page: SignIn, isShowHeader: false },
  {
    path: "/",
    page: Employee,
    isShowHeader: true,
  },
  {
    path: "/timekeeping",
    page: TimeKeeping,
    isShowHeader: true,
  },
  {
    path: "*",
    page: NotFoundPage,
    isShowHeader: true,
  },
  {
    path: "/form",
    page: Form,
  },
  {
    path: "/job-title",
    page: JobTitle,
    isShowHeader: true,
  },
  {
    path: "/project-management",
    page: ProjectManagement,
    isShowHeader: true,
  },
  {
    path: "/on-leave",
    page: OnLeave,
    isShowHeader: true,
  },
  {
    path: "/apartments",
    page: Apartments,
    isShowHeader: true,
  },
  {
    path: "/customer",
    page: Customers,
    isShowHeader: true,
  },
];
