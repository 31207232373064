import {
  Box,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import iconClose from "../../assets/images/icon-login.png";
const PopupTimeKeepingHistory = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"625px"}>
        <Flex
          p={{ base: "25px 24px 24px 24px" }}
          pb={{ base: "24px" }}
          alignContent={"center"}
          justifyContent={"space-between"}
          borderBottom={"1px solid var(--color-boder)"}
        >
          <Text
            fontSize={{ base: "18px" }}
            fontWeight={600}
            color="#000"
            lineHeight={"normal"}
          >
            Lịch sử chỉnh sửa
          </Text>
          <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
        </Flex>
        <Flex
          p={{ base: "24px" }}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={{ base: "24px" }}
        >
          <Text
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"normal"}
            color="var(--color-header-employee)"
          >
            MSH686868-Nguyễn Thị Ngọc Anh
          </Text>
          <Box p={{ base: "0px 16px 40px 16px" }}>
            <Flex
              width={"100%"}
              border={"1px solid var(--Line)"}
              borderRadius={"8px"}
              flexDirection={"column"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                p={{ base: "12px 12px 8px 12px" }}
                backgroundColor="var(--fill-avatar)"
              >
                <Text
                  fontSize={{ base: "12px" }}
                  fontWeight={400}
                  lineHeight={"normal"}
                >
                  Người chỉnh sửa
                </Text>
                <Text
                  fontSize={{ base: "12px" }}
                  fontWeight={400}
                  lineHeight={"normal"}
                >
                  Thời gian chỉnh sửa
                </Text>
              </Flex>
              <Flex width={"100%"}>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  p={"8px 12px 8px 12px"}
                >
                  <Text
                    fontSize={{ base: "12px" }}
                    fontWeight={500}
                    lineHeight={"140%"}
                    color="var(--color-info-employee)"
                  >
                    Nguyễn Tấn Dũng
                  </Text>
                  <Text
                    fontSize={{ base: "12px" }}
                    fontWeight={500}
                    lineHeight={"140%"}
                    color="var(--color-info-employee)"
                  >
                    08:25:32 29
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default PopupTimeKeepingHistory;
