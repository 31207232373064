import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layouts";
import { routes } from "./routes";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SignIn from "./pages/signIn";
function App() {
  const user = useSelector((state) => state.auth.login?.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.accessToken) {
      navigate("/login");
    }
  }, [dispatch, navigate, user]);

  return (
    <Routes>
    {user?.accessToken ? (
      routes.map((route, index) => {
        const Layout = route.isShowHeader ? DefaultLayout : Fragment;
        const Page = route.page;
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <Layout>
                <Page />
              </Layout>
            }
          />
        );
      })
    ) : (
      <Route path="*" element={<SignIn/>} />
    )}
  </Routes>
  );
}

export default App;