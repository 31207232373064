import iconEmployee from "../assets/images/sider/employee.svg";
import iconSourceOfGoods from "../assets/images/sider/sourceofgoods.svg";
import iconSell from "../assets/images/sider/sell.svg";
import iconSales from "../assets/images/sider/sales.svg";
export const enumMenu = [
  {
    path: "#",
    name: "Quản lý nhân sự",
    icon: iconEmployee,
    children: [
      {
        path: "/",
        name: "Quản lý nhân viên",
      },
      {
        path: "/timekeeping",
        name: "Quản lý chấm công",
      },
      {
        path: "/on-leave",
        name: "Xin nghỉ phép",
      },
      {
        path: "/job-title",
        name: "Xác nhận công và chức danh hàng tháng",
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý nguồn hàng",
    icon: iconSourceOfGoods,
    children: [
      {
        path: "/project-management",
        name: "Quản lý dự án",
      },
      {
        path: "/apartments",
        name: "Quản lý sản phẩm và căn hộ",
      },
      {
        path: "/booking",
        name: "Đợt nhận booking",
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý bán hàng",
    icon: iconSell,
    children: [
      {
        path: "/open-for-sale",
        name: "Quản lý đợt mở bán",
      },
      {
        path: "/customer",
        name: "Quản lý khách hàng",
      },
      {
        path: "/booking",
        name: "Quản lý booking",
      },
      {
        path: "/looking",
        name: "Quản lý looking",
      },
      {
        path: "/hdmb",
        name: "Xác nhận HĐMB",
      },
    ],
  },
  {
    path: "#",
    name: "Quản lý doanh số",
    icon: iconSales,
    children: [
      {
        path: "/commission-calculation",
        name: "Tính hoa hồng",
      },
      {
        path: "/commission-confirmation",
        name: "Xác nhận hoa hồng",
      },
      {
        path: "/sales-management",
        name: "Quản lý doanh số",
      },
    ],
  },
];
