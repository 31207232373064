import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import { SelectControl } from "../../components/form/formControls";
import iconExport from "../../assets/images/product/iconExport.svg";
import iconEdit from "../../assets/images/product/edit.svg";
import iconDetail from "../../assets/images/employee/detail.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import FormNew from "./formCustomers/formNew";

const TableCustomers = ({tableData}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employee: "",
      project: "",
    },
  });
  // table
  const [search, setSearch] = useState("");
  const [project, setProject] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [inputColors, setInputColors] = useState({
    date: "var(--color-text-unclick)",
  });
  const [popupDelete, setPopupDelete] = useState(false);
  const [focusStates, setFocusStates] = useState({});

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const saleAreas = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ]

  const handlePopupDelete = () => {
    setPopupDelete(true);
    onOpen();
  }

  const handlePopupEdit = () => {
    setPopupDelete(false);
    onOpen();
  }
      
  const handleFocus = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeProject = (option) => {
    setValue("project", Number(option));
  };

  const onchangeEmployee = (option) => {
    setValue("employee", Number(option));
  };

  //excel
  const handleDownload = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, "data.csv");
  };

  return (
    <>
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={{ base: "32px" }}
          p={"16px 16px 12px 16px"}
        >
            <Flex alignItems={"center"} gap={{ base: "32px" }} flex={"1"}>
                <InputGroup
                    maxW="506px"
                    borderRadius={{ base: "8px" }}
                    w={{ base: "100%" }}
                >
                    <Input
                    placeholder="Nhập Sđt,tên kh,.."
                    type="text"
                    borderRadius={{ base: "8px" }}
                    border={{ base: "1px solid var(--color-boder)" }}
                    onChange={onChangeSearch}
                    />
                    <InputRightElement
                    borderTopRightRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                    pointerEvents="none"
                    bg="var(--color-boder)"
                    cursor={"pointer"}
                    >
                    <SearchIcon color="var(--color-black)" />
                    </InputRightElement>
                </InputGroup>
                <Flex alignItems={"center"} gap={"24px"}>
                    <SelectControl
                        bdTop= {true}
                        label="Dự án"
                        dataOption={saleAreas}
                        placeholder="Chọn dự án"
                        selectedMain={watch("project")}
                        onChange={onchangeProject}
                        onFocus={() => handleFocus("project")}
                        onBlur={() => handleBlur("project")}
                        state={focusStates["project"]}
                    />
                     <SelectControl
                        bdTop= {true}
                        label="Nhân viên tạo"
                        dataOption={saleAreas}
                        placeholder="Chọn nhân viên tạo"
                        selectedMain={watch("employee")}
                        onChange={onchangeEmployee}
                        onFocus={() => handleFocus("employee")}
                        onBlur={() => handleBlur("employee")}
                        state={focusStates["employee"]}
                    />
                </Flex>
            </Flex>
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={()=>handleDownload(tableData.data)}
            >
                <Image src={iconExport} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Xuất exel
                </Text>
            </Button>             
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {tableData.headers.map((e, index) => {
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "") {
                      width = "2%";
                      textAlign = "center";
                    } else if (e.key === "contact") {
                      width = "5%";
                      textAlign = "start";
                    } else if (e.key === "status") {
                      width = "10%";
                      textAlign = "center";
                    } else if (e.key === "name") {
                      width = "25%";
                      textAlign = "start";
                    } else if (e.key === "identificationNumber") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "identificationInfo") {
                      width = "10%";
                      textAlign = "start";
                    } 
                    else if (e.key === "currentAddress") {
                      width = "40%";
                      textAlign = "end";
                    }
                    else if (e.key === "project") {
                      width = "20%";
                      textAlign = "end";
                    } else {
                      width = "20%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {tableData.data?.length > 0 ? (
                  tableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"}>
                        <Td p={"16px 16px 8px 16px"} w={"20%"}>
                            <Menu>
                            <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                            >
                                <AiOutlineMore fontSize={"20px"} />
                            </MenuButton>
                            <MenuList
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                lineHeight={"140%"}
                                color={"var(--color-info-employee)"}
                            >
                                <MenuItem
                                    gap={"16px"}
                                >
                                    <Image src={iconDetail} /> Xem chi tiết
                                </MenuItem>
                                <MenuItem
                                  onClick={handlePopupEdit}
                                  gap={"16px"}
                                >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                </MenuItem>
                                <MenuItem
                                    gap={"16px"}
                                    onClick={handlePopupDelete}
                                >
                                    <Image src={icontrash} /> Xóa khách hàng
                                </MenuItem>
                            </MenuList>
                            </Menu>
                        </Td>
                        <Td>{rowIndex+1}</Td>
                        {keyValues.map((keyvalue, index) => { 
                          let width = "auto";
                          let widthText = "100%";
                          let textAlign = "auto";
                          let whiteSpace = "inherit";
                          if (keyvalue === "") {
                            width = "2%";
                            textAlign = "center";
                          } else if (keyvalue === "contact") {
                            width = "5%";
                            textAlign = "start";
                          } else if (keyvalue === "status") {
                            width = "10%";
                            textAlign = "center";
                          } else if (keyvalue === "name") {
                            width = "25%";
                            textAlign = "start";
                            whiteSpace = "normal";
                            widthText = "118px";
                          } else if (keyvalue === "identificationInfo") {
                            width = "10%";
                            textAlign = "start";
                          } else if (keyvalue === "identificationNumber") {
                            width = "10%";
                            textAlign = "start";
                          }
                          else if (keyvalue === "permanentAddress") {
                            width = "40%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "171px";
                          }
                          else if (keyvalue === "currentAddress") {
                            width = "40%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "146px";
                          }
                          else if (keyvalue === "project") {
                            width = "20%";
                            textAlign = "end";
                            whiteSpace = "normal";
                            widthText = "143px";
                          }
                          else {
                            width = "20%";
                            textAlign = "end";
                            whiteSpace = "inherit";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index}  >
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                color={"#293755"}
                                h={"39.2px"}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}  
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={tableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>Showing 1 to 10 of 12 entries</Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={8}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={8}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />}
                  nextLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />}
                  renderOnZeroPageCount={null}
                  initialPage={8 - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
    {
    !popupDelete ? <FormNew
      isOpen={isOpen}
      onClose={onClose}
      isEdit={true}
    />
    :
    <DeleteDialog 
      isOpen={isOpen}
      onClose={onClose}
      title="Xóa khách hàng"
      description= {<Text display={"inline"}>"Bạn có chắc chắn muốn xóa khách hàng <Text fontWeight={"600"} display={"inline"}>Tên khách hàng</Text> này không?"</Text>} 
      action
    />
    }
    </>
  );
};

export default TableCustomers;