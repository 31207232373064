import { Box, Flex, Image, Text } from "@chakra-ui/react";
import iconFile from "../../assets/images/projectManagement/icon-file.svg";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "id":
      return <Text textAlign={"center"}>{value}</Text>;
    case "image":
      const isImage = value;
      return (
        <Box>
          {isImage ? (
            <Image width={"100%"} h={"48px"} src={isImage}></Image>
          ) : (
            <Box h={"48px"} w={"100%"} backgroundColor={"#D9D9D9"}></Box>
          )}
        </Box>
      );
    case "nameproject":
      return <Text textAlign={"start"}>{value}</Text>;
    case "owner":
      return <Text textAlign={"start"}>{value}</Text>;
    case "classify":
      return <Text textAlign={"start"}>{value}</Text>;
    case "address":
      return <Text textAlign={"end"}>{value}</Text>;
    case "city":
      return <Text textAlign={"end"}>{value}</Text>;
    default:
      return (
        <Flex justifyContent={"flex-end"}>
          <Image src={iconFile} />
        </Flex>
      );
  }
};
