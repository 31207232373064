import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  Button,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
  MenuButton,
  Image,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useEmployee } from "../../../hooks/useEmployee";
import { formatTableValue } from "./formatTable";
import SearchableSelect from "../../../components/selectForm";
import { AiOutlineMore } from "react-icons/ai";
import iconEdit from "../../../assets/images/employee/edit.svg";
import iconClock from "../../../assets/images/timeKeeping/clock.svg";
import calendarIcon from "../../../assets/images/onleave/calendar.svg";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import PopUpTimeKeeping from "../../../components/popup/popupTimeKeepingEdit";
import PopupTimeKeepingHistory from "../../../components/popup/popupTimeKeepingHistory";
import { usePopupForm } from "../../../contexts/usePopupForm";
import toast from "react-hot-toast";
import { checkinPlaceCall } from "../../../api/checkinPlace";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'; 
const AttendanceList = () => {
  const { popupTimeKeepingHistory, setpopupTimeKeepingHistory } =
    usePopupForm();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [dataEdit, setDataEdit] = useState({});

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  //
  const [inputColors, setInputColors] = useState({
    dateStartEp: "var(--color-text-unclick)",
    dateEndEp: "var(--color-text-unclick)",
    dateGuaranteeEp: "var(--color-text-unclick)",
    dateOfIdentificationEp: "var(--color-text-unclick)",
    dateSignFundEp: "var(--color-text-unclick)",
  });

  const handleDateChange = (e, inputName) => {
    const selectedDate = e.target.value;

    setInputColors((prevColors) => ({
      ...prevColors,
      [inputName]: selectedDate
        ? "var(--color-info-employee)"
        : "var(--color-text-unclick)",
    }));
  };
  //
  const historyTableData = {
    headers: [
      {
        label: "",
        key: "",
      },
      {
        label: "Ngày tháng",
        key: "num",
      },
      {
        label: "Thời gian check in",
        key: "time",
      },
      {
        label: "Mã nhân viên",
        key: "msv",
      },
      {
        label: "Tên nhân viên",
        key: "name",
      },
      {
        label: "Địa điểm chấm",
        key: "jobtitle",
      },
      {
        label: "Ngày công",
        key: "partsblock",
      },
      {
        label: "Lí do",
        key: "block",
      },
    ],
    data: [
      {
        num: "13/08/2023",
        time: "08:45:56",
        msv: "MSH45678",
        name: "Nguyễn Thị Ngọc Anh",
        address: "Số 19 đường Trần Văn Trà, Quận 7, TP. Hồ Chí Minh",
        partsblock: "1",
        block: "Check in đúng giờ",
      },
      {
        num: "13/08/2023",
        time: "08:45:56",
        msv: "MSH45678",
        name: "Nguyễn Thị Ngọc Anh",
        address: "Số 19 đường Trần Văn Trà, Quận 7, TP. Hồ Chí Minh",
        partsblock: "1",
        block: "Check in đúng giờ",
      },
    ],
  };
  //

  const options1 = [
    { key: 1, value: "Chi nhánh Hà Nội" },
    { key: 2, value: "Chi nhánh tp Hồ Chí Minh" },
    { key: 3, value: "Chi nhánh Hưng Yên" },
    { key: 4, value: "Chi nhánh Đồng Nai" },
  ];
  const options2 = [
    { key: 1, value: "Khối kế toán" },
    { key: 2, value: "Khối hành chính nhân sự" },
    { key: 3, value: "Khối công đoàn" },
    { key: 4, value: "Khối marketing" },
    { key: 5, value: "Khối kinh doanh" },
    { key: 6, value: "Khối IT" },
  ];

  const handleOpenEdit = (data) => {
    onOpen();

    let id = "670504c0ef59968b823f486a";
    let datas = {
      status: 0,
      name: "Hà Nội 123",
      location: {
          lat: 10.762622,
          lng: 106.66017
      },
      timeForSale: {
          start: "08:00",
          end: "10:00"
      },
      lateTimeForSale: "11:00",
      lateTimeForSupport: "11:00",
      checkInTypes: "1"
  }

    setDataEdit(datas);
  };

  const onSubmit = async (id, data) => {  
    const toastCreate = toast.loading("Đang thêm nhân viên ...");
    try {
      // let status = data.status === 1 ? false : true;
      // let checkInTypes = data.checkInTypes === '0' ? ['ANH'] : ['QRCODE'];
      
      id = "670504c0ef59968b823f486a";
      let filteredData = {
        status: true,
        name: "Hà Nội 123",
        location: {
            lat: 10.762622,
            lng: 106.66017
        },
        timeForSale: {
            "start": "08:00",
            "end": "10:00"
        },
        lateTimeForSale: "11:00",
        timeForSupport: {
            start: "08:30",
            end: "09:00"
        },
        lateTimeForSupport: "11:00",
        checkInTypes: ["QRCODE", "IMAGE"]
    }
    
      await checkinPlaceCall.update(id, filteredData); 
      onToggle();
      toast.success("Update thành công");
    
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi sửa");
    }
      
    toast.dismiss(toastCreate);
  };

  return (
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        minHeight="calc(100vh - 167px)"
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          gap={{ base: "32px" }}
          p={"16px 0px 12px 16px"}
        >
          <InputGroup width={"300px"} borderRadius={{ base: "8px" }}>
            <Input
              h={"44px"}
              placeholder="Nhập MVN, họ tên, sđt..."
              type="text"
              borderRadius={{ base: "8px" }}
              border={{ base: "1px solid var(--color-secondary)" }}
            />
            <InputRightElement
              h={"44px"}
              borderTopRightRadius={"8px"}
              borderBottomRightRadius={"8px"}
              pointerEvents="none"
              backgroundImage="var(--color-button)"
            >
              <SearchIcon color="var(--color-main)" />
            </InputRightElement>
          </InputGroup>

          <Flex alignItems={"center"} gap={{ base: "12px" }}>
          <InputGroup>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Từ ngày - Đến ngày"
                className="datePickerStyle"
              />
              {(!startDate && !endDate) && <InputRightElement pointerEvents="none" h={"44px"}>
                <Image src={calendarIcon} />
              </InputRightElement>}
            </InputGroup>
            <SearchableSelect
              isShowSearch={false}
              options={options1}
              initialSelectedOption= {{key:0, value: "Chọn chi nhánh"}}
            />
            <SearchableSelect
              isShowSearch={false}
              options={options2}
              initialSelectedOption={{key:0, value: "Chọn khối"}}
            />
          </Flex>
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          width={"100%"}
          minH="calc(100vh - 235px)"
        >
          <TableContainer w={"100%"} pb={"32px"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {historyTableData.headers.map((e, index) => { 
                    let width = "auto";
                    let textAlign = "auto";
                    if (e.key === "") {
                      width = "2%";
                      textAlign = "center";
                    } else if (e.key === "num") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "time") {
                      width = "10%";
                      textAlign = "start";
                    } else if (e.key === "partsblock") {
                      width = "10%";
                      textAlign = "center";
                    } else {
                      width = "20%";
                      textAlign = "end";
                    }
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={width}
                      >
                        <Box textAlign={textAlign}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {historyTableData.data?.length > 0 ? (
                  historyTableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"}>
                        <Td p={"16px 16px 8px 16px"} w={rowIndex===0 ? "20px": 'auto'}>
                          <Menu>
                            <MenuButton
                              _hover={{ backgroundColor: "none" }}
                              _active={{ backgroundColor: "none" }}
                              background="none"
                              as={Button}
                            >
                              <AiOutlineMore fontSize={"20px"} />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                alignItems={"center"}
                                display={"flex"}
                                gap={"16px"}
                                onClick={() =>
                                  handleOpenEdit(e)
                                }
                              >
                                <Image src={iconEdit} />
                                <Text
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color="var(--color-option-employee)"
                                >
                                  Chỉnh sửa
                                </Text>
                              </MenuItem>
                              <MenuItem
                                alignItems={"center"}
                                display={"flex"}
                                gap={"16px"}
                                onClick={() => setpopupTimeKeepingHistory(true)}
                              >
                                <Image src={iconClock} />
                                <Text
                                  fontSize={{ base: "14px" }}
                                  fontWeight={500}
                                  lineHeight={"140%"}
                                  color="var(--color-option-employee)"
                                >
                                  Lịch sử chỉnh sửa
                                </Text>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                        {keyValues.map((keyvalue, index) => { 
                          let width = "auto";
                          let widthText = "100%";
                          let textAlign = "auto";
                          let whiteSpace = "inherit";
                          if (keyvalue === "") {
                            width = "2%";
                            textAlign = "center";
                          } else if (keyvalue === "num") {
                            width = "10%";
                            textAlign = "start";
                          } else if (keyvalue === "time") {
                            width = "10%";
                            textAlign = "start";
                          } else if (keyvalue === "partsblock") {
                            width = "10%";
                            textAlign = "center";
                          }
                          else {
                            width = "20%";
                            textAlign = "end";
                            whiteSpace = "inherit";
                          }
                          return (
                            <Td p={"16px 16px 8px 16px"} w={width} key={index}>
                              <Box
                                fontSize={{ base: "14px" }}
                                lineHeight={"19.6px"}
                                textAlign={textAlign}
                                fontWeight={500}
                                w={widthText}
                                whiteSpace={whiteSpace}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                sx={{
                                  WebkitLineClamp: "2",
                                  WebkitBoxOrient: "vertical",
                                }}  
                                color="var(--color-option-employee)"
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={historyTableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex
            m={"50px 16px 16px 16px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              fontWeight={500}
              lineHeight={"20px"}
              color={"var(--text-color-Subtittle)"}
            >
              Showing 1 to 10 of {historyTableData.headers.length} entries
            </Text>
            <HStack gap={"6px"} justify="flex-end">
              <Button
                p={"8px"}
                borderRadius={"4px"}
                isDisabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
                border={"0.5px solid var(--Line)"}
                backgroundColor={"#FFF"}
              >
                <IoIosArrowBack />
              </Button>

              {[1, 2, 3].map((page) => (
                <Button
                  p={"8px"}
                  border={"0.5px solid var(--Line)"}
                  key={page}
                  onClick={() => handlePageChange(page)}
                  bg={
                    currentPage === page
                      ? "var(--color-secondary)"
                      : "transparent"
                  }
                  color={currentPage === page ? "white" : "gray.500"}
                  _hover={{
                    bg:
                      currentPage === page
                        ? "var(--color-secondary)"
                        : "transparent",
                  }}
                >
                  {page}
                </Button>
              ))}

              <Button
                p={"8px"}
                borderRadius={"4px"}
                border={"0.5px solid var(--Line)"}
                backgroundColor={"#FFF"}
                isDisabled={currentPage === 3}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                <IoIosArrowForward />
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </Flex>
      <PopUpTimeKeeping 
        isOpen={isOpen} 
        onClose={onClose} 
        onSubmit={onSubmit}
        defaultValues={dataEdit}
      />
      <PopupTimeKeepingHistory
        isOpen={popupTimeKeepingHistory}
        onClose={() => setpopupTimeKeepingHistory(false)}
      />
    </Flex>
  );
};

export default AttendanceList;
