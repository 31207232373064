import React, { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const TabProvider = ({ children }) => {
  const [employeeTab, setEmployeeTab] = useState(0);

  return (
    <TabContext.Provider
      value={{
        employeeTab,
        setEmployeeTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export const useTab = () => useContext(TabContext);