import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import iconUpFile from "../../assets/images/product/iconUpFile.svg";
import plus from "../../assets/images/product/plus.svg";
import TableCustomers from "./tableCustomers";
import FormNew from "./formCustomers/formNew";
import * as XLSX from "xlsx";
import toast from 'react-hot-toast';

export default function Customers() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tableData, setTableData] = useState({
      headers: [
        {
          label: "",
          key: "",
        },
        {
          label: "STT",
          key: "",
        },
        {
          label: "Tên",
          key: "name",
        },
        {
          label: "SĐT&Email",
          key: "contact",
        },
        {
          label: "Số CCCD",
          key: "identificationNumber",
        },
        {
          label: "Ngày cấp&N/c CCCD",
          key: "identificationInfo",
        },
        {
          label: "Đ/c thường trú",
          key: "permanentAddress",
        },
        {
          label: "Chỗ ở hiện tại",
          key: "currentAddress",
        },
        {
          label: "Nghề nghiệp",
          key: "career",
        },
        {
          label: "Dự án quan tâm",
          key: "project",
        },
        {
          label: "Phân loại",
          key: "type",
        },
        {
          label: "Nhân viên tạo",
          key: "employee",
        },
        {
          label: "Ngày tạo",
          key: "dateCreate",
        },
      ],
      data: [
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2 Tên dự án 1 Tên dự án 2 Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
        {name: 'Nguyễn Thị Ngọc Anh',contact: {phone: '01234567890', email: 'anhntn99@gmai.com'}, identificationNumber: '036099001560', identificationInfo: {dateOfIdentification: '20/11/2021', placeOfIdentification: 'Đống Đa, Hà Nội'}, permanentAddress: 'Hoàng Nam, Nghĩa Hưng, Nam Định', currentAddress: 'Đường láng, Láng Hạ, Đống Đa, Hà Nội', career: 'Kinh doanh tự do', project: 'Tên dự án 1 Tên dự án 2', type: 'Kinh doanh', employee: 'Nguyễn Tấn Dũng', dateCreate: '16/08/2024'},
      ],
    });

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
          // Hiện thông báo tải file
          const loadingToastId = toast.loading("Đang tải file...");
  
          const reader = new FileReader();
          reader.onload = (e) => {
              const binaryStr = e.target.result;
              const workbook = XLSX.read(binaryStr, { type: "binary" });
  
              const firstSheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[firstSheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet);
  
              setTableData((prevState) => ({
                  ...prevState,
                  data: jsonData,
              }));
  
              toast.success("Tải file thành công!");
              toast.dismiss(loadingToastId);
              event.target.value = null;
          };
  
          reader.onerror = () => {
              toast.error("Đã xảy ra lỗi khi tải file.");
              toast.dismiss(loadingToastId);
          };
  
          reader.readAsBinaryString(file);
      } else {
          toast.error("Vui lòng chọn một file.");
      }
  };
  

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
          Quản lý khách hàng
          </Text>
        </Flex>
        <Flex alignItems={"center"} gap={"16px"} >
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                position={"relative"}
                overflow={"hidden"}
            >
                <Image src={iconUpFile} zIndex={1} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Tải file lên
                </Text>
                <Input 
                  type="file" 
                  accept=".xlsx,.csv"
                  fontSize={{ base: "15px" }}
                  fontWeight={600}
                  color="var(--color-main)"
                  opacity={"0"}
                  position={"absolute"}
                  zIndex={2}
                  w={"100%"}
                  h={"200%"}
                  bottom={"0"}
                  left={"0"}
                  cursor={"pointer"}
                  pointerEvents={"auto"}
                  onChange={handleFileUpload}
                />
            </Button>
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={onOpen}
            >
                <Image src={plus} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Khách hàng mới
                </Text>
            </Button>
        </Flex>
        
      </Flex>
      <Box mt={{ base: "23px" }}><TableCustomers tableData={tableData} /></Box>
      <FormNew
          isOpen={isOpen}
          onClose={onClose}
        />
    </Box>
  );
}
