import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Text, useDisclosure } from "@chakra-ui/react";
import { useTab } from "../../contexts/useTab";
import iconExcel from "../../assets/images/timeKeeping/excel.svg";
import iconFile from "../../assets/images/timeKeeping/file.svg";
import AttendanceList from "./AttendanceList";
import TimeKeepingPoints from "./TimekeepingPoints";
import iconButton from "../../assets/images/timeKeeping/icon-button.svg";
import PopUpTimeKeeping from "../../components/popup/popupTimeKeepingEdit";
import { commonCall } from "../../api/common";
import toast from "react-hot-toast";
import { checkinPlaceCall } from "../../api/checkinPlace";
import { useTimeKeeping } from "../../hooks/useTimeKeeping";

export default function TimeKeeping() {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { employeeTab, setEmployeeTab } = useTab();

  const { refetch } = useTimeKeeping(employeeTab);

  const tabEmployee = [
    {
      title: "Danh sách chấm công",
      content: <AttendanceList />,
      isShowButton: true,
    },
    {
      title: "Danh sách điểm chấm công",
      content: <TimeKeepingPoints />,
      isShowButton: false,
    },
  ];

  const handleOpen = () => {
    onOpen();
  }

  const onSubmit = async (data) => { 
    const toastCreate = toast.loading("Đang thêm địa điểm ...");
    try {
      let status = data.status === 1 ? false : true;
      let checkInTypes = data.checkInTypes === '0' ? ['IMAGE'] : ['QRCODE'];
      
      let filteredData = {
        status,
        name: data.name,
        location: data.location,  
        timeForSale: data.timeForSale, 
        lateTimeForSale: "23:59",
        timeForSupport: data.timeForSupport,  
        lateTimeForSupport: "23:59",
        checkInTypes: checkInTypes, 
      };
      console.log(filteredData)
      await checkinPlaceCall.create(filteredData); 
      await refetch();
      onToggle();
      toast.success("Thêm thành công");
    
    } catch (error) {
      console.error("Error during check-in creation:", error);
      toast.error("Có lỗi xảy ra khi thêm");
    }
      
    toast.dismiss(toastCreate);
  };

  return (
    <Box
      minH="calc(100vh - 80px)"
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px 15px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý chấm công
          </Text>
          <Flex
            justifyContent={{ base: "space-around", xl: "flex-start" }}
            gap={{ base: "24px" }}
          >
            {tabEmployee.map((e, index) => {
              return (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  key={index}
                  onClick={() => setEmployeeTab(index)}
                  cursor={"pointer"}
                  padding={"12px 5px 0px 5px"}
                  zIndex={"10"}
                >
                  <Text
                    position={"relative"}
                    paddingBottom={"8px"}
                    _before={{
                      content: '""',
                      position: "absolute",
                      bottom: "-1px",
                      left: 0,
                      width: "100%",
                      height: {
                        base: employeeTab === index ? "2px" : "0",
                      },
                      backgroundColor: "var(--color-secondary)",
                    }}
                    fontSize={{
                      base: "16px",
                    }}
                    fontWeight={600}
                    lineHeight={"normal"}
                    fontFamily="var(--font-heading-main)"
                    color={
                      employeeTab === index
                        ? "var(--color-secondary)"
                        : "#9D9D9D"
                    }
                    textAlign={"center"}
                    whiteSpace="nowrap"
                  >
                    {e?.title}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        {tabEmployee[employeeTab].isShowButton ? (
          <Flex alignItems={"center"} gap={"16px"}>
            <Button
              display={"flex"}
              alignItems={"center"}
              p={"10px 16px 10px 12px"}
              gap={"8px"}
              backgroundImage="var(--color-gradient-excel)"
              _hover={{
                backgroundImage: "var(--color-gradient-excel)",
              }}
            >
              <Image src={iconExcel} />
              <Text
                fontSize={{ base: "15px" }}
                fontWeight={600}
                color="var(--color-main)"
              >
                Xuất exel
              </Text>
            </Button>
            <Button
              display={"flex"}
              alignItems={"center"}
              p={"10px 16px 10px 12px"}
              gap={"8px"}
              backgroundImage="var(--color-button)"
              _hover={{
                backgroundImage: "var(--color-button)",
              }}
            >
              <Image src={iconFile} />
              <Text
                fontSize={{ base: "15px" }}
                fontWeight={600}
                color="var(--color-main)"
              >
                Tải file lên
              </Text>
            </Button>
          </Flex>
        ) : (
          <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--color-button)"
            _hover={{
              backgroundImage: "var(--color-button)",
            }}
            onClick={handleOpen}
          >
            <Image src={iconButton} />
            <Text
              fontSize={{ base: "15px" }}
              fontWeight={600}
              color="var(--color-main)"
            >
              Điểm chấm công mới
            </Text>
          </Button>
        )}
      </Flex>
      <Box mt={{ base: "23px" }}>{tabEmployee[employeeTab].content}</Box>
      <PopUpTimeKeeping isOpen={isOpen} onClose={onClose} onSubmit= {onSubmit} />
    </Box>
  );
}
