import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { clientAPI } from "../api/client";

const NUMBER_NFT_PER_PAGE = 5;
const queryKey = "timekeeping";

async function fetchTimeKeeping(currentPage) {
  try {
    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };
    const queryParams = new URLSearchParams(options).toString();
    let data = await clientAPI("get", `/checkin/place/list/?${queryParams}`); 
    const totalPages = Math.ceil(data?.pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = data?.pagination?.total;
    const startEntry =
      (data?.pagination?.page - 1) * data?.pagination?.limit + 1;
    const endEntry = Math.min(
      data?.pagination?.page * data?.pagination?.limit,
      entries
    );

    const newData = data?.items.map((item, index) => { 
      return {
        num: index + 1 + NUMBER_NFT_PER_PAGE * (currentPage - 1),
        status: item?.status,
        name: item?.name,
        timesupport: `${item?.timeForSupport.start}-${item?.timeForSupport.end}`,
        timelate: item?.lateTimeForSupport,
        date: item?.createdAt,
        employeecreate: item?.createdBy.fullName,
        edit: {
          id: item._id,
          status: item?.status,
        },
        editData: {
          checkInTypes: item.checkInTypes,
          location: item.location,
          timeForSale: item.timeForSale,
          timeForSupport: item.timeForSupport,
          lateTimeForSale: item.lateTimeForSale,
          lateTimeForSupport: item.lateTimeForSupport,
        }
      };
    });

    return { data: newData, totalPages, entries, startEntry, endEntry };
  } catch (error) {
    console.error("Error fetching timekeeping data:", error);
    return { data: [], totalPages: 0 };
  }
}

export function useTimeKeeping() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [queryKey, currentPage],
    queryFn: () => fetchTimeKeeping(currentPage),
    refetchOnWindowFocus: false,
    staleTime: 30000,
  });

  const nextPage = () => {
    if (currentPage < data?.totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return {
    timekeepingData: data?.data,
    totalPages: data?.totalPages,
    refetch,
    isLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
  };
}