import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import iconExcel from "../../assets/images/employee/iconExcel.svg";
import TableOnLeave from "./tableOnLeave";

export default function JobTitle() {
  const tableData = {
    headers: [
      {
        label: "Thời gian tạo",
        key: "timeCreate",
      },
      {
        label: "Mã nhân viên",
        key: "code",
      },
      {
        label: "Tên nhân viên",
        key: "fullName",
      },
      {
        label: "Ngày bắt đầu nghỉ",
        key: "dateStart",
      },
      {
        label: "Số ngày nghỉ",
        key: "number",
      },
      {
        label: "Phân loại",
        key: "type",
      },
      {
        label: "Lí do nghỉ",
        key: "reason",
      },
      {
        label: "Người duyệt",
        key: "reviewer",
      },
      {
        label: "Ngày duyệt",
        key: "dateApproval",
      },
    ],
    data: [
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH1', fullName: 'Giang Văn Đạt', dateStart: '14/08/2024', number: '1', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Lê Đỗ Phương Oanh", jobTitle: "Phó phòng"}, dateApproval: '14/08/2024'},
      {timeCreate: '02:58 25/12/2021',code: 'MSH2', fullName: 'Giang Đạt Văn', dateStart: '14/08/2024', number: '2', type: 'Nghỉ phép', reason: 'Đây là lí do xin nghỉ', reviewer: {name:"Nguyễn Hoàng Anh Tuấn", jobTitle: "Trưởng phòng"}, dateApproval: '14/08/2024'},
    ],
  };

  return (
    <Box
      w={{ base: "100%" }}
      backgroundColor="var(--color-backgroundmain)"
      p={{ base: "24px 16px 16px" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Flex alignItems={"center"} gap={{ base: "74px" }}>
          <Text fontSize={{ base: "20px" }} fontWeight={600}>
            Quản lý nghỉ phép
          </Text>
        </Flex>
        <Button
            display={"flex"}
            alignItems={"center"}
            p={"10px 16px 10px 12px"}
            gap={"8px"}
            backgroundImage="var(--linear-gradient-excel)"
            _hover={{
                filter: "brightness(90%)",
            }}
            >
            <Image src={iconExcel} />
            <Text
                fontSize={{ base: "15px" }}
                fontWeight={600}
                color="var(--color-main)"
            >
                Xuất excel
            </Text>
            </Button>
      </Flex>
      <Box mt={{ base: "23px" }}><TableOnLeave tableData={tableData} /></Box>
    </Box>
  );
}