import {
  InputRightElement,
  Box,
  Flex,
  Input,
  Select,
  Text,
  InputGroup,
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Spinner,
  MenuItem,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useDebounce from "../../hooks/useDebounce";
import { CiSearch } from "react-icons/ci";
import { IoCheckmarkOutline } from "react-icons/io5";

export function InputControl({
  label,
  placeholder,
  type = "text",
  state, 
  value,
  onChange,
  onFocus,
  onBlur,
  validation, 
  bdTop = false,
  errors,
  register, 
  defaultValues,
  mwidth,
  colorIp
}) { 
  return (
    <FormControl
      flex={"1"}
      maxW={mwidth ? "100%" : "259px"} 
      display={"flex"}
      flexDirection={"column"}
      gap={bdTop ? "8px" : "0px"}
      h={bdTop ? "44px" : "max-content"} 
      borderBottom={bdTop ? state ? "1px solid var(--color-option-employee-hover)" : "1px solid var(--color-boder)" : "none"}
    >
      <FormLabel
        lineHeight={"15px"}
        m="0"
        fontSize={{ base: "12px" }}
        fontWeight={400}
        color={state ? "var(--color-option-employee-hover)" : bdTop ? "var(--color-black-label)" : "var(--color-label-employee)"}
      >
        {label}
      </FormLabel>

      <Input
        h={bdTop ? "max-content" : "44px"}
        fontSize={{ base: "14px" }}
        lineHeight={"17px"}
        fontWeight={"500"}
        padding={bdTop ? "0" : "13px 12px 14px 12px"}
        borderRadius={"8px"}
        border={bdTop ? "none" : "0.5px solid var(--bg-line-employee)"}
        placeholder={placeholder}
        display={type==="date"? "block" : "flex"}
        color= {colorIp ? colorIp : "inherit" }
        sx={{
          "&::placeholder": {
            color: colorIp ? colorIp : bdTop ? "var(--text-gray-form)" : "var(--color-placeHolder-employee)",
          },
        }}
        _focus={{
          boxShadow: "none",
          borderColor: "var(--color-option-employee-hover)",
        }}
        
        {...register}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type={type}
      />

      {errors && (
        <Text fontSize={{ base: "12px" }} color={"red"} lineHeight={"15px"}>
          {errors.message}
        </Text>
      )}
    </FormControl>
  );
};

export function SelectControl({
  label,
  dataOption,
  placeholder,
  selectedMain,
  onChange,
  errorMessage,
  registerProps,
  isSearchable,
  onFocus,
  onBlur,
  state,
  bdTop,
  mwidth,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedActive, setSelectedActive] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setLoading(false);
    }
  }, [debouncedSearchTerm]);

  const filteredOptions = useMemo(() => {
    return dataOption.filter((option) =>
      option.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [debouncedSearchTerm, dataOption]);

  const onChangeAction = (value) => {
    if (value === null || value === "" || value === undefined) {
      onChange(value); 
    } else {
      onChange(Number(value)); 
    }
    setSelectedActive(true);
    onChange(value);
  };

  const check = dataOption.find(
    (option) => Number(option.value) === Number(selectedMain)
  );

  return (
    <Box
      w={"100%"}
      borderRadius={bdTop ? "0px" : "12px"}
      maxW={mwidth ? "100%" : "259px"}
      display={"flex"}
      flexDirection={"column"}
      gap={bdTop ? "8px" : "0px" }
      h={bdTop ? "44px" : "max-content"}
      borderBottom={bdTop ? "1px solid var(--color-boder)" : "none"}
    >
      {label && (
        <Text
          fontSize={{ base: "12px" }}
          fontWeight={400}
          lineHeight={"15px"}
          mb={bdTop ? "0" : "6px"}
          color={
            state
              ? "var(--color-option-employee-hover)"
              : bdTop
              ? "var(--color-black-label)"
              : "var(--color-label-employee)"
          }
        >
          {label}
        </Text>
      )}

      <Menu matchWidth>
        <MenuButton
          as={Button}
          _active={{
            border: bdTop ? "none" : "1px solid var(--color-option-employee-hover)",
          }}
          _hover={{
            boxShadow: "none",
          }}
          rightIcon={<ChevronDownIcon color="#000" />}
          w="100%"
          backgroundColor="#FFF"
          border={bdTop ? "none" : "1px solid var(--bg-line-employee)"}
          fontSize="14px"
          fontWeight={500}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          textAlign={"left"}
          p={bdTop ? "0" : "16px"}
          lineHeight={"17px"}
          h={bdTop ? "17px" : "44px"}
          color={
            (selectedActive || selectedMain) ? "var(--color-info-employee)" : bdTop ? "var(--text-gray-form)" : "var(--color-placeHolder-employee)"
          }
          {...registerProps} 
        >
          {check ? check.label : placeholder}
        </MenuButton>

        <MenuList
          borderRadius="12px"
          minW="275px"
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {isSearchable && (
            <Flex
              alignItems="center"
              gap="12px"
              padding="7px 16px"
              borderBottom="1px solid var(--bg-line-employee)"
            >
              <CiSearch color="var(--Text-color-Disable)" fontSize="20px" />
              <Input
                placeholder="Tìm kiếm..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fontSize="14px"
                fontWeight={500}
                border="none"
                _focus={{ boxShadow: "none" }}
                _placeholder={{ color: "var(--color-placeHolder-employee)" }}
              />
              {loading && <Spinner size="sm" />}
            </Flex>
          )}

          {!loading && filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => onChangeAction(option.value)}
                backgroundColor={
                  Number(selectedMain) === Number(option.value)
                    ? "#F9FAFD"
                    : "#FFF"
                }
                color={
                  Number(selectedMain) === Number(option.value)
                    ? "#1A9ED0"
                    : "#000"
                }
              >
                {option.label}
                {Number(selectedMain) === Number(option.value) && (
                  <IoCheckmarkOutline style={{ marginLeft: "auto" }} />
                )}
              </MenuItem>
            ))
          ) : (
            <Box fontSize="14px" p="12px" textAlign="center">
              Không tìm thấy kết quả
            </Box>
          )}
        </MenuList>
      </Menu>

      {!selectedMain && errorMessage && (
        <Text fontSize="12px" color="red" lineHeight={"15px"}>
          {errorMessage.message}
        </Text>
      )}
    </Box>
  );
}


export function InputDateControl({ label, placeholder, padding }) {
  return (
    <FormControl>
      {label && (
        <FormLabel display={"block"} mb={"6px"}>
          {label}
        </FormLabel>
      )}
      <InputGroup padding={padding ? padding : "13px 12px 14px 12px"}>
        <Input
          placeholder={placeholder ? placeholder : "Select Date and Time"}
          type="datetime-local"
        />
        <InputRightElement pointerEvents="none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.6667 1.33325V3.99992"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.33325 1.33325V3.99992"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 6.66675H14"
              stroke="#293755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}

export function RadioControl({
  options,
  selectedValue,
  onChange,
  errorMessage,
}) {
  return (
    <Box>
      <RadioGroup
        onChange={onChange}
        value={Number(selectedValue) || 0}
        mb={"16px"}
      >
        <Stack spacing={"36px"} direction="row">
          {options.map((option, index) => (
            <Radio
              border={"2px solid var(--border-input-employee)"}
              value={option?.value}
              key={index}
            >
              {option?.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {errorMessage && (
        <Text fontSize={{ base: "12px" }} color="red">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
}