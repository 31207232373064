import React, { useCallback, useState } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  HStack,
  Img,
  Button,
  Image,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { formatTableValue } from "./formatTable";
import chevronLeft from "../../assets/images/employee/chevron-left.svg";
import chevronRight from "../../assets/images/employee/chevron-right.svg";
import useDebounce from "../../hooks/useDebounce";
import ReactPaginate from "react-paginate";
import { SelectControl } from "../../components/form/formControls";
import iconExport from "../../assets/images/product/iconExport.svg";
import iconEdit from "../../assets/images/product/edit.svg";
import icontrash from "../../assets/images/product/trash.svg";
import { AiOutlineMore } from "react-icons/ai";
import DeleteDialog from "../../components/dialog/delete";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";

const TableApartments = ({tableData}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project: "",
      typeApartment: "",
    },
  });
  // table
  const [search, setSearch] = useState("");
  const [project, setProject] = useState(null);
  const [typeApartment, setTypeApartment] = useState(null);
  const [inputColors, setInputColors] = useState({
    date: "var(--color-text-unclick)",
  });
  const [popupDelete, setPopupDelete] = useState(false);
  const [focusStates, setFocusStates] = useState({});

  const debouncedSearchTerm = useDebounce(search, 500);
  const onChangeSearch = useCallback((e) => {
    const { value } = e.target;
    setSearch(value);
  });

  const saleAreas = [
    { value: "0", label: "Đang làm việc" },
    { value: "1", label: "Dừng làm việc" },
  ]

  const handlePopupDelete = () => {
    setPopupDelete(!popupDelete);
    onOpen();
  }
      
  const handleFocus = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => { 
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeTypeApartment = (option) => {
    setValue("typeApartment", Number(option));
  };

  //excel
  const handleDownload = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    XLSX.writeFile(workbook, "data.csv");
  };

  return (
    <>
    <Flex flexDirection={"column"} gap={{ base: "16px" }}>
      <Flex
        backgroundColor="var(--color-main)"
        borderRadius={{ base: "12px" }}
        flexDirection={"column"}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={{ base: "32px" }}
          p={"16px 16px 12px 16px"}
        >
            <Flex alignItems={"center"} gap={{ base: "32px" }} flex={"1"}>
                <InputGroup
                    maxW="506px"
                    borderRadius={{ base: "8px" }}
                    w={{ base: "100%" }}
                >
                    <Input
                    placeholder="Nhập MVN, họ tên, sđt..."
                    type="text"
                    borderRadius={{ base: "8px" }}
                    border={{ base: "1px solid var(--color-boder)" }}
                    onChange={onChangeSearch}
                    />
                    <InputRightElement
                    borderTopRightRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                    pointerEvents="none"
                    bg="var(--color-boder)"
                    cursor={"pointer"}
                    >
                    <SearchIcon color="var(--color-black)" />
                    </InputRightElement>
                </InputGroup>
                <Flex alignItems={"center"} gap={"24px"}>
                    <SelectControl
                        bdTop= {true}
                        label="Dự án"
                        dataOption={saleAreas}
                        placeholder="Chọn dự án"
                        selectedMain={watch("project")}
                        onChange={onchangeTypeApartment}
                        onFocus={() => handleFocus("project")}
                        onBlur={() => handleBlur("project")}
                        state={focusStates["project"]}
                    />
                     <SelectControl
                        bdTop= {true}
                        label="Phân loại"
                        dataOption={saleAreas}
                        placeholder="Chọn loại hình"
                        selectedMain={watch("typeApartment")}
                        onChange={onchangeTypeApartment}
                        onFocus={() => handleFocus("typeApartment")}
                        onBlur={() => handleBlur("typeApartment")}
                        state={focusStates["typeApartment"]}
                    />
                </Flex>
            </Flex>
            <Button
                w={"176px"}
                h={"44px"}
                display={"flex"}
                alignItems={"center"}
                p={"10px 16px 10px 12px"}
                gap={"8px"}
                background="var(--color-black)"
                _hover={{
                    filter: "brightness(90%)",
                }}
                onClick={()=>handleDownload(tableData.data)}
            >
                <Image src={iconExport} />
                <Text
                    fontSize={{ base: "15px" }}
                    fontWeight={600}
                    color="var(--color-main)"
                >
                    Xuất exel
                </Text>
            </Button>             
        </Flex>
        <Box width={"100%"} height={"100%"}>
          <TableContainer w={"100%"}>
            <Table w={"100%"} variant="unstyled">
              <Thead
                w={"100%"}
                h={"41px"}
                color="white"
                fontWeight="400"
                backgroundColor="#F9FAFD"
              >
                <Tr h={{ base: "41px" }}>
                  {tableData.headers.map((e, index) => {
                    return (
                      <Td
                        p={"16px 16px 8px 16px"}
                        key={index}
                        border={"none"}
                        color={"#51607B"}
                        fontSize={{ base: "14px" }}
                        w={"20%"}
                      >
                        <Box textAlign={"center"}>{e.label}</Box>
                      </Td>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody w={"100%"} h={"100%"}>
                {tableData.data?.length > 0 ? (
                  tableData.data?.map((e, rowIndex) => {
                    const keyValues = Object.keys(e);
                    return (
                      <Tr key={rowIndex} h={"72px"}>
                        <Td p={"16px 16px 8px 16px"} w={"20%"}>
                            <Menu>
                            <MenuButton
                                _hover={{ backgroundColor: "none" }}
                                _active={{ backgroundColor: "none" }}
                                background="none"
                                as={Button}
                            >
                                <AiOutlineMore fontSize={"20px"} />
                            </MenuButton>
                            <MenuList
                                fontSize={{ base: "14px" }}
                                fontWeight={500}
                                lineHeight={"140%"}
                                color={"var(--color-info-employee)"}
                            >
                                <MenuItem
                                    gap={"16px"}
                                >
                                    <Image src={iconEdit} /> Chỉnh sửa
                                </MenuItem>
                                <MenuItem
                                    gap={"16px"}
                                    onClick={handlePopupDelete}
                                >
                                    <Image src={icontrash} /> Xóa SP căn hộ
                                </MenuItem>
                            </MenuList>
                            </Menu>
                        </Td>
                        <Td>{rowIndex+1}</Td>
                        {keyValues.map((keyvalue, index) => {
                          return (
                            <Td p={"16px 16px 8px 16px"} w={'auto'} key={index}>
                              <Box
                                fontSize={{ base: "14px" }}
                                textAlign={"center"}
                                fontWeight={500}
                                color={"#293755"}
                                h={"140%"}
                              >
                                {formatTableValue(e[keyvalue], keyvalue)}
                              </Box>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })
                ) : (
                  <Tr>
                    <Td
                      colSpan={tableData.headers.length}
                      textAlign="center"
                      padding={"40px 0"}
                    >
                      Không có dữ liệu
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          
          <Box mt={10} mr={5} mb={5} ml={5}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>Showing 1 to 10 of 12 entries</Text>
              <HStack spacing={2} justify="flex-end">
                <ReactPaginate
                  className="pagination-custom"
                  pageCount={8}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={8}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  breakClassName={"ellipsis"}
                  breakLabel={"..."}
                  previousLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronLeft} />}
                  nextLabel={<Img w={"16px"} minW={"16px"} h={"16px"} src={chevronRight} />}
                  renderOnZeroPageCount={null}
                  initialPage={8 - 1}
                />
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Flex>
    
    <DeleteDialog 
      isOpen={isOpen}
      onClose={onClose}
      title="Xóa sản phẩm và căn hộ"
      description= {<Text display={"inline"}>"Bạn có chắc chắn muốn xóa sản phẩm và căn hộ <Text fontWeight={"600"} display={"inline"}>Tên căn hộ</Text> này không?"</Text>} 
      action
    />
    </>
  );
};

export default TableApartments;