import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import iconClose from "../../assets/images/icon-login.png";
import { FormTimeKeeping } from "../form/formTimeKeeping";
import SearchableSelect from "../selectForm";
import { RadioControl, SelectControl } from "../form/formControls";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { isEndTimeGreater } from "../../utils/utils";

const schema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập địa điểm chấm công"),
  location: Yup.object().shape({
    lat: Yup.number()
      .transform((value, originalValue) => (originalValue === "" ? null : value)) 
      .required("Vui lòng nhập tọa độ lat")
      .min(0, "Tọa độ lat phải lớn hơn hoặc bằng 0")
      .max(90, "Tọa độ lat phải nhỏ hơn hoặc bằng 90"),
    lng: Yup.number()
      .transform((value, originalValue) => (originalValue === "" ? null : value)) 
      .required("Vui lòng nhập tọa độ lng")
      .max(180, "Tọa độ lng phải nhỏ hơn hoặc bằng 180")
      .test(
        'lng-greater-than-lat',
        'Tọa độ lng phải lớn hơn tọa độ lat',
        function(value) {
          const { lat } = this.parent;
          return value > lat;
        }
      ),
  }),
  timeForSale: Yup.object().shape({
    start: Yup.string()
      .required("Vui lòng nhập thời gian bắt đầu đúng giờ")
      .matches(
        /^([01]\d|2[0-3]):([0-5]\d)$/,
        "Thời gian không hợp lệ, định dạng phải là HH:mm"
      ),
    end: Yup.string()
      .required("Vui lòng nhập thời gian kết thúc đúng giờ")
      .matches(
        /^([01]\d|2[0-3]):([0-5]\d)$/,
        "Thời gian không hợp lệ, định dạng phải là HH:mm"
      )
      .test(
        'is-end-greater',
        'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
        function (value) {
          const { start } = this.parent;
          return isEndTimeGreater(start, value);
        }
      ),
  }),
  timeForSupport: Yup.object().shape({
    start: Yup.string()
      .required("Vui lòng nhập thời gian bắt đầu đúng giờ")
      .matches(
        /^([01]\d|2[0-3]):([0-5]\d)$/,
        "Thời gian không hợp lệ, định dạng phải là HH:mm"
      ),
    end: Yup.string()
      .required("Vui lòng nhập thời gian kết thúc đúng giờ")
      .matches(
        /^([01]\d|2[0-3]):([0-5]\d)$/,
        "Thời gian không hợp lệ, định dạng phải là HH:mm"
      )
      .test(
        'is-end-greater',
        'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
        function (value) {
          const { start } = this.parent;
          return isEndTimeGreater(start, value); 
        }
      ),
  }),
  // lateTimeForSale: Yup.string()
  //   .required("Vui lòng nhập thời gian bắt đầu muộn")
  //   .matches(
  //     /^([01]\d|2[0-3]):([0-5]\d)$/,
  //     "Thời gian không hợp lệ, định dạng phải là HH:mm"
  // ),
  // lateTimeForSupport: Yup.string()
  //   .required("Vui lòng nhập thời gian kết thúc muộn")
  //   .matches(
  //     /^([01]\d|2[0-3]):([0-5]\d)$/,
  //     "Thời gian không hợp lệ, định dạng phải là HH:mm"
  // ),
  checkInTypes: Yup.string().required("Vui lòng chọn loại check-in"),
  status: Yup.number().default(0), 
});

const PopUpTimeKeeping = ({ isOpen, onClose, idEdit, onSubmit, defaultValues }) => { console.log(defaultValues)
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues ? defaultValues : {},
  });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const [focusStates, setFocusStates] = useState({});

  const status = [
    { value: 0, label: "Sử dụng" },
    { value: 1, label: "Không sử dụng" },
  ];

  const options = [
    { value: "0", label: "Ảnh" },
    { value: "1", label: "QR code" },
  ];

  const handleFocus = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setFocusStates((prev) => ({ ...prev, [field]: false }));
  };

  const onchangeCheckInTypes = (option) => {
    console.log(option);
    setValue("checkInTypes", Number(option));
  };

  const handleActionStateChange = (option) => {
    setValue("status", option);
  };

  const submitHandler = (data) => {
    idEdit ? onSubmit(idEdit,data) : onSubmit(data);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={"625px"}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Flex
            p={{ base: "25px 24px 24px 24px" }}
            pb={{ base: "24px" }}
            alignContent={"center"}
            justifyContent={"space-between"}
            borderBottom={"1px solid var(--color-boder)"}
          >
            <Text
              fontSize={{ base: "18px" }}
              fontWeight={600}
              color="#000"
              lineHeight={"normal"}
            >
              {defaultValues ? "Sửa địa điểm chấm công" : "Tạo địa điểm chấm công"}
            </Text>
            <Image src={iconClose} onClick={onClose} cursor={"pointer"} />
          </Flex>
          <Flex
            p={{ base: "24px" }}
            flexDirection={"column"}
            justifyContent={"space-between"}
          >
            <Flex flexDirection={"column"} gap={{ base: "12px" }}>
              <FormTimeKeeping
                title={"Điểm điểm chấm công"}
                place={"Nhập địa điểm chấm công"}
                isIconPlace={true}
                isIconTime={false}
                length={"100%"}
                register={register("name")} 
                errors={errors.name}
              />

              <Flex alignItems={"center"} gap={{ base: "8px" }}>
                <FormTimeKeeping
                  title={"Vị trí (lat)"}
                  place={"Tọa độ lat"}
                  isIconPlace={false}
                  isIconTime={false}
                  length={"126px"}
                  register={register("location.lat")} 
                  errors={errors.location?.lat}
                />
                <FormTimeKeeping
                  title={"Vị trí (long)"}
                  place={"Tọa độ long"}
                  isIconPlace={false}
                  isIconTime={false}
                  length={"126px"}
                  register={register("location.lng")}
                  errors={errors.location?.lng}
                />
              </Flex>
              <Flex 
                gap={"25px"} p={"20px 0"} 
                borderTop={"1px solid var(--border-input-employee)"}
                borderBottom={"1px solid var(--border-input-employee)"}>
                <Box>
                <Text fontSize={"14px"} lineHeight={"16.94px"} fontWeight={600} mb={"12px"}>Nhân viên kinh doanh</Text>
                  <Flex gap={"8px"}>
                    <FormTimeKeeping
                      title={"TG đúng giờ"}
                      place={"Từ giờ"}
                      type="time"
                      isIconPlace={false}
                      isIconTime={true}
                      length={"126px"}
                      register={register("timeForSale.start")} 
                      errors={errors.timeForSale?.start}
                    />
                    <FormTimeKeeping
                      title={"TG muộn giờ"}
                      place={"Đến giờ"}
                      type="time"
                      isIconPlace={false}
                      isIconTime={true}
                      length={"126px"}
                      register={register("timeForSale.end")}
                      errors={errors.timeForSale?.end}
                    />
                  </Flex>
                </Box>
                <Box>
                <Text fontSize={"14px"} lineHeight={"16.94px"} fontWeight={600} mb={"12px"}>Nhân viên hỗ trợ kinh doanh</Text>
                
                  <Flex gap={"8px"}>
                    <FormTimeKeeping
                      title={"TG đúng giờ"}
                      place="Từ giờ"
                      type="time"
                      isIconPlace={false}
                      isIconTime={true}
                      length="126px"
                      register={register("timeForSupport.start")} 
                      errors={errors.timeForSupport?.start}
                    />
                    <FormTimeKeeping
                      type="time"
                      title={"TG muộn giờ"}
                      place={"Đến giờ"}
                      isIconPlace={false}
                      isIconTime={true}
                      length={"126px"}
                      register={register("timeForSupport.end")} 
                      errors={errors.timeForSupport?.end}
                    />
                  </Flex>
                </Box>
                
              </Flex>
              <SelectControl
                label="Yêu cầu thêm"
                dataOption={options}
                placeholder="Chọn thêm yêu cầu"
                selectedMain={watch("checkInTypes")}
                onChange={onchangeCheckInTypes}
                onFocus={() => handleFocus("checkInTypes")}
                onBlur={() => handleBlur("checkInTypes")}
                state={focusStates["checkInTypes"]}
                registerProps={register("checkInTypes")} 
                errorMessage={errors.checkInTypes}
              />
              <FormLabel
                  fontSize={{ base: "12px" }}
                  fontWeight={400}
                  color={"var(--color-label-employee)"}
                  mb={"12px"}
                >
                  Trạng thái hoạt động
                </FormLabel>

                <RadioControl
                  options={status}
                  selectedValue={watch("status")}
                  onChange={handleActionStateChange}
                />
            </Flex>
            <Flex justifyContent={"flex-end"} mt={"165px"}>
              <Button
                type="submit"
                color={"#FFF"}
                fontSize={"15px"}
                fontWeight={600}
                lineHeight={"normal"}
                backgroundImage="var(--color-button)"
                _hover={{
                  backgroundImage: "var(--color-button)",
                }}
              >
                {defaultValues ? "Sửa điểm CC" : "Tạo điểm CC"}
              </Button>
            </Flex>
          </Flex>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default PopUpTimeKeeping;
