import React from 'react'
import FormEmployee from '../../components/form/formEmployee'
import {usePopupForm} from '../../contexts/usePopupForm'
import {usePopupFormInfo} from '../../contexts/usePopupFormInfo'
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import FormInfo from '../../components/form/formInfo';

function Index() {
  const {popupFormInfoContextVisible,setPopupFormInfoContextVisible} = usePopupFormInfo();

  const handelPopupInfo = () => {
    setPopupFormInfoContextVisible(true);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box>
      {/* <Button onClick={onOpen} >Bật/tắt Form</Button>   */}
      <Button onClick={onOpen} >Bật/tắt Form Edit</Button>  
      <Button onClick={handelPopupInfo} >Bật/tắt FormInfo</Button>  
      <FormEmployee isOpen={isOpen} onClose={onClose} />
      {popupFormInfoContextVisible ? <FormInfo /> : null}
    </Box>
  )
}

export default Index