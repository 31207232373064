import { Text } from "@chakra-ui/react";

export const formatTableValue = (value, key) => {
  switch (key) {
    case "num":
      return <Text>{value}</Text>;
    case "avatar":
      return <Text>{value}</Text>;
    case "msv":
      return <Text>{value}</Text>;
    case "name":
      return <Text>{value}</Text>;
    case "jobtitle":
      return <Text>{value}</Text>;
    case "partsblock":
      return <Text>{value}</Text>;
    case "block":
      return <Text>{value}</Text>;
    case "region":
      return <Text>{value}</Text>;
    case "branch":
      return <Text>{value}</Text>;
    case "email":
      return <Text>{value}</Text>;
    case "cccd":
      return <Text>{value}</Text>;
    case "time":
      const timestamp = value;
      const date = new Date(timestamp);
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return <Text>{formattedDate}</Text>;
    case "address":
      return <Text>{value}</Text>;
    case "bank":
      return <Text>{value}</Text>;
    case "dayoff":
      const time = value;
      const datestamp = new Date(time);
      const formatted = datestamp.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
      return <Text>{formatted}</Text>;
    default:
      return <Text>{value}</Text>;
  }
};
