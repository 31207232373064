import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { clientAPI } from "../api/client";
import { formatDate, formatDateForInput } from "../utils/utils";

const NUMBER_NFT_PER_PAGE = 5;
const queryKey = "employee";

async function fetchConstantData() {
  const searchOptions = {
    limit: 100,
    page: 1,
  };
  const searchQueryParams = new URLSearchParams(searchOptions).toString();

  // Fetch constant data concurrently
  const [
    jobTitleData,
    departmentData,
    saleBlockData,
    saleAreaData,
    branchData,
    supportDepartmentData,
    referralCodeData,
    businessCategoryData,
    guaranteeTypeData,
  ] = await Promise.all([
    clientAPI("get", `/jobTitle?${searchQueryParams}`),
    clientAPI("get", `/department?${searchQueryParams}`),
    clientAPI("get", `/saleBlock?${searchQueryParams}`),
    clientAPI("get", `/saleArea?${searchQueryParams}`),
    clientAPI("get", `/branch?${searchQueryParams}`),
    clientAPI("get", `/supportDepartment?${searchQueryParams}`),
    clientAPI("get", `/referralCode?${searchQueryParams}`),
    clientAPI("get", `/businessCategory?${searchQueryParams}`),
    clientAPI("get", `/guaranteeType?${searchQueryParams}`),
  ]);

  // Index data by 'id' to improve lookup performance
  return {
    jobTitles: jobTitleData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    departments: departmentData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    saleBlocks: saleBlockData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    saleAreas: saleAreaData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    branches: branchData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    supportDepartments: supportDepartmentData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    referralCodes: referralCodeData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    businessCategorys: businessCategoryData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
    guaranteeTypes: guaranteeTypeData?.items?.reduce(
      (acc, item) => ({ ...acc, [item.id]: item.name }),
      {}
    ),
  };
}

async function fetchEmployee({
  currentPage,
  department,
  search,
  branch,
  saleBlock,
  saleArea,
  supportDepartmentId,
  constantData,
}) {
  try {
    if (!constantData) {
      return {
        data: [],
        totalpages: 0,
        entries: 0,
        startEntry: 0,
        endEntry: 0,
      };
    }

    const options = {
      limit: NUMBER_NFT_PER_PAGE,
      page: currentPage,
    };
    if (department !== undefined) options.department = department;
    if (search !== undefined) options.search = search;
    if (branch) options.branch = branch;
    if (saleBlock) options.saleBlock = saleBlock;
    if (saleArea) options.saleArea = saleArea;
    if (supportDepartmentId) options.supportDepartmentId = supportDepartmentId;
    const queryParams = new URLSearchParams(options).toString();

    // Fetch employee data
    let { items, pagination } = await clientAPI(
      "get",
      `/employee?${queryParams}`
    );

    // Transform the employee data
    let newData = [];
    if (department) {
      newData = items?.map((item, index) => {
        return {
          num: index + 1 + NUMBER_NFT_PER_PAGE * (currentPage - 1),
          avatar: item?.avatar,
          code: item?.code || "-",
          fullName: item?.fullName || "-",
          jobTitle: constantData.jobTitles[item?.jobTitle] || "-",
          department: constantData.departments[item?.department] || "-",
          saleBlock: constantData.saleBlocks[item?.saleBlock] || "-",
          saleArea: constantData.saleAreas[item?.saleArea] || "-",
          branch: constantData.branches[item?.branch] || "-",
          contact: [item?.email, item?.phone],
          identificationNumber: item?.identificationNumber || "-",
          identificationNumberInfo: [
            item?.dateOfIdentification
              ? formatDate(formatDateForInput(item?.dateOfIdentification))
              : "-",
            item?.placeOfIdentification,
          ],
          currentAddress: item?.currentAddress,
          bankInfo: [item?.bankAccount, item?.bankName],
          startTime: item?.startTime
            ? formatDate(formatDateForInput(item?.startTime))
            : "-",
          endTime: item?.endTime
            ? formatDate(formatDateForInput(item?.endTime))
            : "-",
          status: item?.status,
          detail: {
            ...(item?.code && { code: item.code }),
            ...(item?.fullName && { fullName: item.fullName }),
            ...(item?.password && { password: item.password }),
            ...(item?.phone && { phone: item.phone }),
            ...(item?.email && { email: item.email }),
            ...(item?.avatar && { avatar: item.avatar }),
            ...(item?.jobTitle &&
              constantData?.jobTitles[item?.jobTitle] && {
                jobTitle: constantData.jobTitles[item.jobTitle],
              }),
            ...(item?.identificationNumber && {
              identificationNumber: item.identificationNumber,
            }),
            ...(item?.dateOfIdentification && {
              dateOfIdentification: item.dateOfIdentification,
            }),
            ...(item?.placeOfIdentification && {
              placeOfIdentification: item.placeOfIdentification,
            }),
            ...(item?.permanentAddress && {
              permanentAddress: item.permanentAddress,
            }),
            ...(item?.currentAddress && {
              currentAddress: item.currentAddress,
            }),
            ...(item?.bankAccount && { bankAccount: item.bankAccount }),
            ...(item?.bankName && { bankName: item.bankName }),
            ...(item?.startTime && { startTime: item.startTime }),
            ...(item?.endTime && { endTime: item.endTime }),
            status: item?.status,
            ...(constantData?.departments[item?.department] && {
              department: constantData.departments[item.department],
            }),
            ...(item?.jobPosition && { jobPosition: item.jobPosition }),
            ...(item?.supportDepartmentId && {
              supportDepartmentId: item.supportDepartmentId,
            }),
            ...(item?.businessCategoryId && {
              businessCategoryId: item.businessCategoryId,
            }),
            ...(item?.guaranteeTypeId && {
              guaranteeTypeId: item.guaranteeTypeId,
            }),
            ...(item?.guaranteeDate && { guaranteeDate: item.guaranteeDate }),
            ...(item?.depositDate && { depositDate: item.depositDate }),
            ...(item?.referralCodeId &&
              constantData?.referralCodes[item?.referralCodeId] && {
                referralCodeId: constantData.referralCodes[item.referralCodeId],
              }),
            ...(item?.saleBlock &&
              constantData?.saleBlocks[item?.saleBlock] && {
                saleBlock: constantData.saleBlocks[item.saleBlock],
              }),
            ...(item?.saleArea &&
              constantData?.saleAreas[item?.saleArea] && {
                saleArea: constantData.saleAreas[item.saleArea],
              }),
            ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
            ...(item?.branch &&
              constantData?.branches[item?.branch] && {
                branch: constantData.branches[item.branch],
              }),
          },
          edit: {
            ...(item?.id && { id: item.id }),
            ...(item?.code && { code: item.code }),
            ...(item?.fullName && { fullName: item.fullName }),
            ...(item?.phone && { phone: item.phone }),
            ...(item?.email && { email: item.email }),
            ...(item?.avatar && { avatar: item.avatar }),
            ...(item?.jobTitle && { jobTitle: item.jobTitle }),
            ...(item?.identificationNumber && {
              identificationNumber: item.identificationNumber,
            }),
            ...(item?.dateOfIdentification && {
              dateOfIdentification: formatDateForInput(
                item.dateOfIdentification
              ),
            }),
            ...(item?.placeOfIdentification && {
              placeOfIdentification: item.placeOfIdentification,
            }),
            ...(item?.permanentAddress && {
              permanentAddress: item.permanentAddress,
            }),
            ...(item?.currentAddress && {
              currentAddress: item.currentAddress,
            }),
            ...(item?.bankAccount && { bankAccount: item.bankAccount }),
            ...(item?.bankName && { bankName: item.bankName }),
            ...(item?.startTime && {
              startTime: formatDateForInput(item.startTime),
            }),
            ...(item?.endTime && { endTime: formatDateForInput(item.endTime) }),
            status: item?.status,
            department: item?.department,
            ...(item?.jobPosition && { jobPosition: item.jobPosition }),
            ...(item?.supportDepartmentId && {
              supportDepartmentId: item.supportDepartmentId,
            }),
            ...(item?.businessCategoryId && {
              businessCategoryId: item.businessCategoryId,
            }),
            ...(item?.guaranteeTypeId && {
              guaranteeTypeId: item.guaranteeTypeId,
            }),
            ...(item?.guaranteeDate && {
              guaranteeDate: formatDateForInput(item.guaranteeDate),
            }),
            ...(item?.depositDate && {
              depositDate: formatDateForInput(item.depositDate),
            }),
            ...(item?.referralCodeId && {
              referralCodeId: item.referralCodeId,
            }),
            ...(item?.saleBlock && { saleBlock: item.saleBlock }),
            ...(item?.saleArea && { saleArea: item.saleArea }),
            ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
            ...(item?.branch && { branch: item.branch }),
          },
        };
      });
    } else {
      newData = items?.map((item, index) => {
        return {
          num: index + 1 + NUMBER_NFT_PER_PAGE * (currentPage - 1),
          avatar: item?.avatar,
          code: item?.code || "-",
          fullName: item?.fullName || "-",
          jobTitle: constantData.jobTitles[item?.jobTitle] || "-",
          supportDepartment:
            constantData.supportDepartments[item?.supportDepartmentId] || "-",
          branch: constantData.branches[item?.branch] || "-",
          contact: [item?.email, item?.phone],
          identificationNumber: item?.identificationNumber || "-",
          identificationNumberInfo: [
            item?.dateOfIdentification
              ? formatDate(formatDateForInput(item?.dateOfIdentification))
              : "-",
            item?.placeOfIdentification,
          ],
          currentAddress: item?.currentAddress,
          bankInfo: [item?.bankAccount, item?.bankName],
          startTime: item?.startTime
            ? formatDate(formatDateForInput(item?.startTime))
            : "-",
          endTime: item?.endTime
            ? formatDate(formatDateForInput(item?.endTime))
            : "-",
          status: item?.status,
          detail: {
            ...(item?.code && { code: item.code }),
            ...(item?.fullName && { fullName: item.fullName }),
            ...(item?.password && { password: item.password }),
            ...(item?.phone && { phone: item.phone }),
            ...(item?.email && { email: item.email }),
            ...(item?.avatar && { avatar: item.avatar }),
            ...(item?.jobTitle &&
              constantData?.jobTitles[item?.jobTitle] && {
                jobTitle: constantData.jobTitles[item.jobTitle],
              }),
            ...(item?.identificationNumber && {
              identificationNumber: item.identificationNumber,
            }),
            ...(item?.dateOfIdentification && {
              dateOfIdentification: item.dateOfIdentification,
            }),
            ...(item?.placeOfIdentification && {
              placeOfIdentification: item.placeOfIdentification,
            }),
            ...(item?.permanentAddress && {
              permanentAddress: item.permanentAddress,
            }),
            ...(item?.currentAddress && {
              currentAddress: item.currentAddress,
            }),
            ...(item?.bankAccount && { bankAccount: item.bankAccount }),
            ...(item?.bankName && { bankName: item.bankName }),
            ...(item?.startTime && { startTime: item.startTime }),
            ...(item?.endTime && { endTime: item.endTime }),
            status: item?.status,
            ...(constantData?.departments[item?.department] && {
              department: constantData.departments[item.department],
            }),
            ...(item?.jobPosition && { jobPosition: item.jobPosition }),
            ...(item?.supportDepartmentId && {
              supportDepartmentId: item.supportDepartmentId,
            }),
            ...(item?.businessCategoryId && {
              businessCategoryId: item.businessCategoryId,
            }),
            ...(item?.guaranteeTypeId && {
              guaranteeTypeId: item.guaranteeTypeId,
            }),
            ...(item?.guaranteeDate && { guaranteeDate: item.guaranteeDate }),
            ...(item?.depositDate && { depositDate: item.depositDate }),
            ...(item?.referralCodeId &&
              constantData?.referralCodes[item?.referralCodeId] && {
                referralCodeId: constantData.referralCodes[item.referralCodeId],
              }),
            ...(item?.saleBlock &&
              constantData?.saleBlocks[item?.saleBlock] && {
                saleBlock: constantData.saleBlocks[item.saleBlock],
              }),
            ...(item?.saleArea &&
              constantData?.saleAreas[item?.saleArea] && {
                saleArea: constantData.saleAreas[item.saleArea],
              }),
            ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
            ...(item?.branch &&
              constantData?.branches[item?.branch] && {
                branch: constantData.branches[item.branch],
              }),
          },
          edit: {
            ...(item?.id && { id: item.id }),
            ...(item?.code && { code: item.code }),
            ...(item?.fullName && { fullName: item.fullName }),
            ...(item?.phone && { phone: item.phone }),
            ...(item?.email && { email: item.email }),
            ...(item?.avatar && { avatar: item.avatar }),
            ...(item?.jobTitle && { jobTitle: item.jobTitle }),
            ...(item?.identificationNumber && {
              identificationNumber: item.identificationNumber,
            }),
            ...(item?.dateOfIdentification && {
              dateOfIdentification: formatDateForInput(
                item.dateOfIdentification
              ),
            }),
            ...(item?.placeOfIdentification && {
              placeOfIdentification: item.placeOfIdentification,
            }),
            ...(item?.permanentAddress && {
              permanentAddress: item.permanentAddress,
            }),
            ...(item?.currentAddress && {
              currentAddress: item.currentAddress,
            }),
            ...(item?.bankAccount && { bankAccount: item.bankAccount }),
            ...(item?.bankName && { bankName: item.bankName }),
            ...(item?.startTime && {
              startTime: formatDateForInput(item.startTime),
            }),
            ...(item?.endTime && { endTime: formatDateForInput(item.endTime) }),
            status: item?.status,
            department: item?.department,
            ...(item?.jobPosition && { jobPosition: item.jobPosition }),
            ...(item?.supportDepartmentId && {
              supportDepartmentId: item.supportDepartmentId,
            }),
            ...(item?.businessCategoryId && {
              businessCategoryId: item.businessCategoryId,
            }),
            ...(item?.guaranteeTypeId && {
              guaranteeTypeId: item.guaranteeTypeId,
            }),
            ...(item?.guaranteeDate && {
              guaranteeDate: formatDateForInput(item.guaranteeDate),
            }),
            ...(item?.depositDate && {
              depositDate: formatDateForInput(item.depositDate),
            }),
            ...(item?.referralCodeId && {
              referralCodeId: item.referralCodeId,
            }),
            ...(item?.saleBlock && { saleBlock: item.saleBlock }),
            ...(item?.saleArea && { saleArea: item.saleArea }),
            ...(item?.JobTitleCode && { JobTitleCode: item.JobTitleCode }),
            ...(item?.branch && { branch: item.branch }),
          },
        };
      });
    }

    const totalpages = Math.ceil(pagination?.total / NUMBER_NFT_PER_PAGE);
    const entries = pagination?.total;
    const startEntry = (pagination?.page - 1) * pagination?.limit + 1;
    const endEntry = Math.min(pagination?.page * pagination?.limit, entries);
    return { data: newData, totalpages, entries, startEntry, endEntry };
  } catch (error) {
    console.log("error", error);
    return { data: [], totalpages: 0, entries: 0, startEntry: 0, endEntry: 0 };
  }
}

export function useEmployee(
  department,
  search,
  branch,
  saleBlock,
  saleArea,
  supportDepartmentId
) {
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch constant data once
  const { data: constantData, isLoading: isConstantDataLoading } = useQuery({
    queryKey: ["constantData"],
    queryFn: fetchConstantData,
  });

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: [
      queryKey,
      currentPage,
      department,
      search,
      branch,
      saleBlock,
      saleArea,
      supportDepartmentId,
    ],
    queryFn: () =>
      fetchEmployee({
        currentPage,
        department,
        search,
        branch,
        saleBlock,
        saleArea,
        supportDepartmentId,
        constantData,
      }),
    enabled: !!constantData && !isConstantDataLoading,
    refetchOnWindowFocus: false,
  });

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    if (data?.totalpages && currentPage > data.totalpages) {
      setCurrentPage(data.totalpages);
    }
  }, [currentPage, data?.totalpages]);

  return {
    employeeData: data?.data,
    totalPages: data?.totalpages,
    refetch,
    isLoading: isLoading || isConstantDataLoading,
    isRefetching,
    prevPage,
    nextPage,
    setCurrentPage,
    currentPage,
    entries: data?.entries,
    startEntry: data?.startEntry,
    endEntry: data?.endEntry,
    constantData,
  };
}