import {
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import iconMap from "../../assets/images/timeKeeping/icon-map.svg";
import iconTime from "../../assets/images/timeKeeping/time.svg";
export const FormTimeKeeping = ({
  type="text",
  title,
  place,
  isIconPlace,
  isIconTime,
  length,
  errors,
  register, 
}) => {
  return (
    <FormControl
      display={"flex"}
      flexDirection={"column"}
      gap={{ base: "6px" }}
      maxW={length}
    >
      <FormLabel
        fontSize={"12px"}
        fontWeight={400}
        color="var(--text-color-Subtittle)"
        lineHeight={"normal"}
        m={{ base: "0" }}
      >
        {title}
      </FormLabel>

      <InputGroup
        mt={"0"}
        p={{ base: "14px 12px 13px 12px" }}
        alignItems={"center"}
        h={"44px"}
        border={"0.5px solid var(--Line)"}
        borderRadius={"8px"}
        _focus={{
          boxShadow: "none",
        }}
      >
        <Input
          color="var(--text-color-Subtittle)"
          fontWeight={500}
          fontSize={"14px"}
          border={"none"}
          p={"0px"}
          placeholder={place}
          type={type}
          _focus={{
            boxShadow: "none",
          }}
          {...register} 
        />
        {/* <InputRightElement
          cursor={"pointer"}
          borderTopRightRadius={"8px"}
          borderBottomRightRadius={"8px"}
          pointerEvents="auto"
        >
          <Image src={iconMap} display={isIconPlace ? "block" : "none"} />
          <Image src={iconTime} display={isIconTime ? "block" : "none"} />
        </InputRightElement> */}
      </InputGroup>

      {errors && (
        <Text fontSize={{ base: "12px" }} color={"red"}>
          {errors.message}
        </Text>
      )}
    </FormControl>
  );
};
